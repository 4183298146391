<template>
    <div>
        <base-header class="pb-1 custom-base-header custom-header-bg">
            <div class="row align-items-center py-4 custom-header">
                <img src="./logo.png" alt="" />
            </div>
        </base-header>
        <div class="container form-container success message welcome-container">
            <div class="card text-center">
                <h1>Welcome to XB!</h1>
                <h2>Thank You for submitting your Advertisement!</h2>
                <p>Please allow up to 24 hours for your listing to be reviewed.</p>
                <p class="text-italic">If you have any issues please contact <a
                        href="mailto:XB Support<info@xbcommunity.com>">info@xbcommunity.com</a></p>
                <div>
                  <button class="btn btn-primary btn-outline mt-2"  @click="$router.push('/register-page')">Register A Business</button>
                  <button class="btn btn-primary mt-2"  @click="$router.push('/advertisement/register')">Submit New Advertisement</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import * as Cookies from "js-cookie";

Vue.use(Cookies);
import { globalStore } from '@/utils/global'
import { paymentSuccess } from '@/api/request/advertisement'

export default {
    beforeMount() {
        const token = Cookies.get('user-token') || globalStore.token
        paymentSuccess(token, this.$route.params.id)
    },
};
</script>

<style lang="scss" scoped>
.custom-header-bg {
  background: var(--primary) !important;
  opacity: 1;
  border-bottom: 10px solid var(--primary);
}
.custom-header {
  img {
    width: 90%;
    max-width: 500px;
    margin: auto;
  }
}
.welcome-container {
  width: 100%;

  .card {
    margin: auto;
    margin-top: 60px;
    max-width: 650px;
    padding: 30px 50px 40px;
    border-top-left-radius: unset;
    border-top-right-radius: unset;
    border-radius: 1rem;
    background-color: white;

    h1 {
      font-size: 3rem;
      color: var(--primary)
    }

    h2 {
      font-size: 1.5rem;
      color: var(--primary)
    }

    p {
      font-size: 1rem;
      color: var(--primary)
    }
  }
}

@media only screen and (max-width: 600px) {
  .welcome-container {
    background-color: white;
    max-width: unset; 
  
    .card {
      box-shadow: none;
      margin-top: 0;
      height: 100%;
      padding-bottom: 60px;
      border-radius: 0;
    }
  }
}
</style>
