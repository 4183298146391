<template>
  <div class="bg-default">
    <!-- Header -->
    <div class="header bg-default py-lg-8 pt-lg-4 pt-6"></div>
    <!-- Page content -->
    <div class="forgot-password container mt--9 resetPassword">
      <div class="row justify-content-center">
        <div class="col-lg-9 col-md-9">
          <card no-body class="bg-secondary">
            <div slot="header" class="row align-items-center">
              <div class="col-lg-10 col-8">
                <h3 class="mb-0">{{ title }}</h3>
              </div>
            </div>

            <div class="card-body border-0 mb-0 p-3">
              <p
                v-show="!verify && !changePass && errorChangePass"
                class="mb-5"
                v-html="message"
              ></p>
              <div v-if="successReset">
                <h3 class="ctitle text-center text-primary">
                  Password Reset Successful!
                </h3>
                <p class="w-100 text-center">
                  You successfully change your password
                  <br /><br /><strong
                    >Redirecting to login page in
                    <span class="counter">{{ countDown }}</span></strong
                  >
                </p>
              </div>
              <div v-if="verify">
                <div v-html="message"></div>
                <form class="forgotPassword" v-show="!success">
                  <p
                    v-show="hasError"
                    :class="{ 'is-invalid': hasError }"
                    class="form-feedback"
                  >
                    {{ error }}
                  </p>
                  <div role="group" class="mb-5">
                    <input
                      type="text"
                      name="email"
                      v-model="email"
                      @input="emailCheck"
                      v-validate="'required|email'"
                      placeholder="Enter your email"
                      class="form-control no-instruction"
                      autocomplete="autocomplete off"
                      :class="{
                        input: true,
                        'is-invalid': errors.has('email'),
                        'has-invalid': errors.has('email'),
                      }"
                    />
                    <div class="invalid-feedback" v-if="errors.has('email')">
                      {{ errors.first("email") }}
                    </div>
                  </div>
                  <button
                    type="button"
                    name="button"
                    v-promise-btn
                    @click="forgotPassword"
                    class="btn btn-primary pr-5 pl-5 btn-block"
                  >
                    Send Request
                  </button>
                </form>
              </div>
              <div v-if="changePass" class="col-lg-12 col-md-12">
                <form class="forgotPassword" v-show="!success">
                  <p
                    v-show="errorChangePass"
                    :class="{ 'is-invalid': errorChangePass }"
                    class="form-feedback"
                  >
                    {{ errorChangePassword | capitalizeWords }}
                  </p>
                  <b-row>
                    <div class="col-lg-6">
                      <b-form-group role="group" class="mb-3 has-feedback">
                        <label
                          class="w-100 form-control-label"
                          for="password"
                          >New Password</label
                        >
                        <input
                          v-if="changePass"
                          type="password"
                          name="password"
                          v-model.trim="password"
                          data-vv-name="password"
                          data-vv-as="password"
                          data-vv-scope="account"
                          @input="passwordCheck"
                          placeholder="Enter password"
                          autocomplete="new-password"
                          v-validate="'required'"
                          class="form-control no-instruction"
                          :class="{
                            'is-invalid':
                              errors.has('account.password') ||
                              passwordCriteriaError,
                            'has-invalid':
                              errors.has('account.password') ||
                              (password.length > 0 && progressWidth <= 40) ||
                              passwordCriteriaError,
                            'has-valid':
                              progressWidth >= 75 &&
                              hasLowercaseUppercase &&
                              hasNumber &&
                              hasSpecialChar,
                            'has-warning':
                              password.length > 0 &&
                              progressWidth > 40 &&
                              progressWidth < 75,
                          }"
                        />
                        <span class="has-icon-feedback"></span>
                        <div
                          class="invalid-feedback"
                          :is-invalid="
                            errors.has('account.password') ||
                            passwordCriteriaError
                          "
                        >
                          {{
                            errors.first("account.password") ||
                            passwordCriteriaError
                          }}
                        </div>
                      </b-form-group>
                      <b-form-group role="group" class="mb-3 has-feedback">
                        <label
                          class="w-100 form-control-label"
                          for="confirmPassword"
                          >Confirm Password</label
                        >
                        <input
                          v-if="changePass"
                          type="password"
                          name="confirmPassword"
                          v-model.trim="confirmPassword"
                          data-vv-as="confirm password"
                          data-vv-name="confirmPassword"
                          data-vv-scope="account"
                          v-validate="`required|confirmPassword:${password}`"
                          placeholder="Re-enter password"
                          autocomplete="new-password"
                          class="form-control no-instruction input"
                          data-vv-delay="1000"
                          :class="{
                            'is-invalid': errors.has('account.confirmPassword'),
                            'has-invalid': errors.has(
                              'account.confirmPassword'
                            ),
                            'has-valid':
                              !errors.has('account.confirmPassword') &&
                              confirmPassword.length >= 8,
                          }"
                        />
                        <span class="has-icon-feedback"></span>
                        <div
                          class="invalid-feedback"
                          :is-invalid="errors.has('account.confirmPassword')"
                        >
                          {{ errors.first("account.confirmPassword") }}
                        </div>
                      </b-form-group>
                    </div>
                    <div class="col-lg-6">
                      <div
                        role="group"
                        class="mb-3 d-none d-lg-block"
                      >
                        <p>
                          Your password must not contain any blank spaces and
                          meet the following criteria:
                        </p>
                        <ul class="list-icon">
                          <li
                            :class="{
                              'icon-check-circle-o muted': password.length == 0,
                              'icon-check-circle valid': password.length >= 8,
                              'icon-warning invalid':
                                password.length < 8 && password.length > 0,
                            }"
                          >
                            8 characters
                          </li>
                          <li
                            :class="{
                              'icon-check-circle-o muted':
                                hasLowerCase === false &&
                                hasUpperCase === false,
                              'icon-exclamation-circle warning':
                                (!hasLowerCase && hasUpperCase) ||
                                (hasUpperCase === false && hasLowerCase),
                              'icon-check-circle valid': hasLowercaseUppercase,
                              'icon-warning invalid':
                                hasLowercaseUppercase === false &&
                                password.length > 0,
                            }"
                          >
                            Uppercase and lowercase letters
                          </li>
                          <li
                            :class="{
                              'icon-check-circle-o muted': password.length == 0,
                              'icon-check-circle valid': hasNumber,
                              'icon-warning invalid':
                                !hasNumber && password.length > 0,
                            }"
                          >
                            Number(s)
                          </li>
                          <li
                            :class="{
                              'icon-check-circle-o muted': password.length == 0,
                              'icon-check-circle valid': hasSpecialChar,
                              'icon-warning invalid':
                                !hasSpecialChar && password.length > 0,
                            }"
                          >
                            Special character(s)
                          </li>
                        </ul>
                        <div class="progress">
                          <div
                            class="progress-bar passwordMeter"
                            role="progressbar"
                            v-bind:style="{ width: progressWidth + '%' }"
                            aria-valuenow="0"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            {{ progressLabel }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-row>
                  <b-row>
                    <div class="col-lg-6"></div>
                    <div class="col-lg-6 text-right mt-5">
                      <button
                        type="button"
                        id="changePassword"
                        name="button"
                        v-promise-btn
                        @click="changePassword"
                        class="btn btn-primary"
                      >
                        Change Password
                      </button>
                    </div>
                  </b-row>
                </form>
              </div>
              <div class="text-center" v-if="errorLink">
                <button
                  @click="redirectToForgotPass()"
                  name="button"
                  class="btn btn-primary pr-5 pl-5"
                >
                  Request Another Link
                </button>
              </div>
              <hr class="my-4" />
              <div class="text-center">
                <router-link
                  to="/login"
                  name="button"
                  class="btn btn-outline-primary pr-5 pl-5"
                  >Back to Sign In</router-link
                >
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Api from '@/api'
// import Errors from '@/utils/errors'
import { Validator } from "vee-validate";
import { BASE_URL, STATIC_URL } from "@/api/constants/baseUrl";

const confirmPassword = {
  getMessage: (field) => `The password confirmation does not match`,
  validate(value, param) {
    return new Promise((resolve) => {
      resolve({
        valid: value.toString() === param[0],
        data:
          value === "" ? { message: `Confirm password field is required` } : "",
      });
    });
  },
};
Validator.extend("confirmPassword", confirmPassword);

export default {
  name: "ForgotPassword",
  data() {
    return {
      account: {},
      email: "",
      password: "",
      confirmPassword: "",
      errorChangePass: false,
      errorChangePassword: "",
      progressWidth: 0,
      progressLabel: "",
      hasNumber: false,
      hasUpperCase: false,
      hasLowerCase: false,
      hasLowercaseUppercase: false,
      hasSpecialChar: false,
      hasPeriodDash: false,

      countDown: 10,
      successReset: false,

      error: "",
      title: "Forgot Password",
      hasError: false,
      success: false,
      verify: true,
      changePass: false,
      message: "Enter your email.",
      staticUrl: STATIC_URL,
      errorLink: false,
      passwordCriteriaError: false,
    };
  },
  methods: {
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
          if (this.countDown === 0) {
            this.$router.push("/login");
          }
        }, 1000);
      }
    },
    forgotPassword() {
      return this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          const input = this.validateInput(this.email);
          const inputValue = input
            ? { email: this.email }
            : { username: this.email };
          const { email } = this;

          return this.$store
            .dispatch("user/FORGOT_PASSWORD_REQUEST", { email })
            .then((res) => {
              if (res.data) {
                if (res.data.success) {
                  this.hasError = false;
                  this.success = true;
                  this.message = `<div class="text-center"><img src="/img/email/email-inbox.png" width="150" alt=""><h4 class="mb-4 text-primary">You've got mail!</h4><p class="m-0">We sent you an email with instructions on how to reset your password</p></div>`;
                } else {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      "Error requesting password reset"
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                }
              }
            })
            .catch((err) => {
              err.then((error) => {
                // this.error = error.errMessage
                // this.hasError = true
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    error.errMessage
                  ),
                  timeout: 4000,
                  icon: "fas fa-exclamation-triangle",
                  type: "danger",
                });
              });
            });
        }
      });
    },
    validateInput(input) {
      const emailFormat =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailFormat.test(input);
    },
    emailCheck: function () {
      this.error = "";
      this.hasError = false;
    },
    passwordCheck: function () {
      this.errorChangePassword = "";
      const password = this.password;
      this.hasNumber = /\d/.test(password);
      this.hasUpperCase = /[A-Z]/.test(password);
      this.hasLowerCase = /[a-z]/.test(password);
      this.hasLowercaseUppercase =
        /[a-z]/.test(password) && /[A-Z]/.test(password);
      this.hasSpecialChar = /[!@#/$%^&*)(+=#><;:,._-]/.test(password);
      this.hasPeriodDash = !/^\s*?\.|-.*$/.test(password);
      this.level = 0;

      var progressBar = document.querySelectorAll(".passwordMeter");
      var charCounts = {};
      var numCount = 0;
      var upperCaseCount = 0;
      var specialCharCount = 0;

      for (var i = 0; i < password.length; i++) {
        if (/\d/.test(password.charAt(i))) numCount++;
        if (/[A-Z]/.test(password.charAt(i))) upperCaseCount++;
        if (/[!@#/$%^&*)(+=#><;:,._-]/.test(password.charAt(i)))
          specialCharCount++;
      }

      charCounts.numCount = numCount;
      charCounts.ucCount = upperCaseCount;
      charCounts.scCount = specialCharCount;

      this.progressWidth = this.calcPassStrength(
        password.length,
        this.hasNumber,
        this.hasLowercaseUppercase,
        charCounts,
        this.hasSpecialChar
      );

      if (this.progressWidth <= 40) {
        [].forEach.call(progressBar, function (el) {
          el.classList.remove("bg-warning");
          el.classList.remove("bg-success");
          el.classList.add("bg-danger");
        });
        this.progressLabel = this.progressWidth >= 30 ? "Bad Password" : "Bad";
        this.passwordCriteriaError = "Password must meet the criteria";
        // this.$validator.errors.add({field: 'password', msg: 'Password must meet the criteria', scope: 'account'})
      } else if (this.progressWidth > 40 && this.progressWidth < 75) {
        [].forEach.call(progressBar, function (el) {
          el.classList.remove("bg-danger");
          el.classList.remove("bg-success");
          el.classList.add("bg-warning");
        });
        this.progressLabel = "Weak Password";
        // this.passwordCriteriaError = 'Password must meet the criteria'
        this.$validator.errors.add({
          field: "password",
          msg: "Password must meet the criteria",
          scope: "account",
        });
      } else if (this.progressWidth >= 75) {
        [].forEach.call(progressBar, function (el) {
          el.classList.remove("bg-warning");
          el.classList.remove("bg-danger");
          el.classList.add("bg-success");
        });
        this.progressLabel = "Strong Password";
        this.passwordCriteriaError = false;
        // this.$validator.errors.remove('password', 'account')
      }
      // console.log(this.$validator.errors)
    },
    calcPassStrength: function (len, num, lowCap, charCounts, specialChar) {
      var level = 0;
      if (len >= 8) {
        level += 18;
      }
      if (charCounts.ucCount === 2) {
        level += 10;
      }
      if (charCounts.ucCount > 2) {
        level += 4;
      }
      if (num) {
        level += 12;
      }
      if (charCounts.numCount > 2) {
        level += 5;
      }
      if (lowCap) {
        level += 22;
      }
      if (specialChar) {
        level += 23;
      }
      if (charCounts.scCount === 2) {
        level += 10;
      }
      if (charCounts.scCount >= 3) {
        level += 12;
      }
      // if (periodDash) { level += 10 }
      if (len === 0) {
        level = 0;
      }
      // console.log(len)
      // console.log(level)
      return level;
    },
    checkPasswordToken(token) {
      if (token) {
        return this.$store
          .dispatch("user/CONFIRM_PASSWORD_TOKEN", token)
          .then((res) => {
            if (res.data) {
              this.verify = false;
              this.changePass = true;
              this.title = "Change Password";
            } else {
              this.verify = true;
              this.changePass = false;
              this.errorChangePass = true;
              this.message = `<p class="is-invalid text-center text-danger text-bold"><strong>Token Invalid. Please request another link or contact your administrator to change your password</strong></p>`;
              // this.errorLink = true
            }
          })
          .catch((err) => {
            console.error(err);
            err.then((error) => {
              this.verify = false;
              this.changePass = false;
              this.errorChangePass = true;
              this.message = `<p class="is-invalid text-center text-danger text-bold"><strong>Token Invalid or ${error.errMessage}. Please request another link or contact your administrator to change your password</strong></p>`;
              // this.errorLink = true
              // this.message = `<h3 class="ctitle">${error.errMessage}</h3>`
            });
          });
      }
    },
    changePassword() {
      const promiseBtn = document.getElementById("changePassword");
      const promiseBtnInner = promiseBtn.querySelector(
        ".promise-btn__spinner-wrapper"
      );
      promiseBtnInner.classList.remove("hidden");
      promiseBtn.setAttribute("disabled", "disabled");

      let password = this.password;
      const token = this.$route.params.token;
      if (this.errors.has("password") || this.errors.has("confirmPassword")) {
        this.errorChangePass = true;
        this.errorChangePassword = "Error/s has been found";
      }
      password = { password };

      return this.$validator.validateAll().then((isValid) => {
        if (isValid && !this.passwordCriteriaError) {
          return this.$store
            .dispatch("user/CHANGE_PASSWORD_REQUEST", { password, token })
            .then((res) => {
              if (res.data.success) {
                this.verify = false;
                this.changePass = false;
                this.errorChangePass = true;
                this.successReset = true;
                this.message = "<br/>";
                /*this.message = `<h3 class="ctitle text-center">Resetting Password Successful!</h3>
                <p class="w-100 text-center ">
                You successfully change your password
                  <br/><br/><strong>Redirecting to login page in {{countDown}}</strong>
                </p>
                `*/
                this.countDownTimer();
              } else {
                this.verify = false;
                this.changePass = false;
                this.errorChangePass = true;
                this.message = `<p class="form-feedback is-invalid text-center">Token Invalid. Please <router-link to="forgot-password">request another link</router-link> to change your password</p>`;
              }
              promiseBtnInner.classList.add("hidden");
              promiseBtn.removeAttribute("disabled", "disabled");
            })
            .catch((err) => {
              console.error(err);
              err.then((error) => {
                var errorMessage =
                  error.errMessage === "session_expired"
                    ? "Forgot password link is already expired"
                    : this.$options.filters.capitalizeWords(error.errMessage);
                this.$notify({
                  message: errorMessage,
                  timeout: 10000,
                  icon: "fas fa-exclamation-triangle",
                  type: "danger",
                });
                this.verify = false;
                this.changePass = true;
                this.errorChangePass = true;
                this.success = false;
                this.message = `<p class="form-feedback is-invalid text-center">${error.errMessage}</p>`;
                this.errorChangePassword = error.errMessage;
                this.confirmPassword = this.password = "";
                this.progress = this.progressWidth = 0;
                this.hasNumber =
                  this.hasUpperCase =
                  this.hasLowerCase =
                  this.hasLowercaseUppercase =
                  this.hasSpecialChar =
                    false;

                promiseBtnInner.classList.add("hidden");
                promiseBtn.removeAttribute("disabled", "disabled");

                this.$validator.reset();
                this.errors.clear();

                this.$router.push("/login");
              });
            });
        }
      });
    },
    redirectToForgotPass() {
      this.$router.push({ path: "/forgot-password" });
      this.email = "";
      this.password = "";
      this.confirmPassword = "";
      this.errorChangePass = false;
      this.errorChangePassword = "";
      this.error = "";
      this.title = "Forgot Password";
      this.hasError = false;
      this.success = false;
      this.verify = true;
      this.changePass = false;
      this.message = "Enter the email you used when you joined.";
      this.errorLink = false;
    },
  },
  beforeMount() {
    if (this.$route.params.type === "user" && this.$route.params.token) {
      this.title = "";
      this.verify = false;
    }

    if (this.$route.params.type === "admin" && this.$route.params.token) {
      this.verify = false;
      this.changePass = true;
      this.title = "Reset Password";
    }

    if (this.$route.params.token) {
      const token = this.$route.params.token;
      this.checkPasswordToken(token);
    }
  },
};
</script>
<style scoped>
.resetPassword .progress {
  height: 0.95rem;
}
.counter {
  font-weight: bold;
}
input.form-control {
  background-color: transparent;
}
input.form-control:disabled,
input.form-control[readonly] {
  background-color: #fff2;
}
</style>
