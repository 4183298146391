<template>
  <div class="profile">
    <notifications></notifications>
    <base-header class="pb-6 custom-base-header custom-header-bg">
      <div class="row align-items-center py-4 custom-header">
        <img src="./logo.png" alt="" />
        <!-- <div class="col-lg-4 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div> -->
      </div>
    </base-header>
    <div class="container mt--6 minHeight form-container">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card no-body body-classes="px-0 pb-1" footer-classes="pb-2" class="createClientContainer">
            <b-row id="processAdminForm">
              <div class="col-lg-12 col-md-12 col-12 remove-padding">
                <b-card no-body>
                  <b-card-body class="p-0 m-0">
                    <b-row>
                      <form class="w-100" v-if="step === 'register'">
                        <div id="business_information">
                          <div class="form-head">
                            <h1>1. Business Information</h1>
                          </div>
                          <div class="form-body">
                            <div class="row">
                              <div class="col-lg-6">
                                <b-form-group role="group" class="mb-3 has-feedback">
                                  <label class="w-100 form-control-label" for="firstname">Business Name
                                  </label>
                                  <input type="text" name="name" v-model.trim="business.name" data-vv-as="Business Name"
                                    v-validate="'required'" data-vv-name="name" placeholder="Business Name"
                                    autocomplete="section-business organization" class="form-control no-instruction"
                                    :class="{
                                      input: true,
                                      'is-invalid': errors.has('name'),
                                      'has-invalid': errors.has('name'),
                                    }" />
                                  <span class="has-icon-feedback"></span>
                                  <div class="invalid-feedback" :is-invalid="errors.has('name')">
                                    {{ errors.first("name") }}
                                  </div>
                                </b-form-group>

                                <b-form-group role="group" class="mb-3 has-feedback">
                                  <label class="w-100 form-control-label" for="lastname">Business Address</label>
                                  <input type="text" name="line1" v-model.trim="
                                    business.location.address.line1
                                  " data-vv-as="Address Line 1" v-validate="'required'" data-vv-name="line1"
                                    placeholder="Address Line 1" autocomplete="section-business address-line1"
                                    class="form-control no-instruction" :class="{
                                      input: true,
                                      'is-invalid': errors.has('line1'),
                                      'has-invalid': errors.has('line1'),
                                    }" />
                                  <span class="has-icon-feedback"></span>
                                  <div class="invalid-feedback" :is-invalid="errors.has('line1')">
                                    {{ errors.first("line1") }}
                                  </div>
                                </b-form-group>

                                <b-form-group role="group" class="mb-3 has-feedback">
                                  <input type="text" name="line2" v-model.trim="
                                    business.location.address.line2
                                  " data-vv-as="Address Line 2" data-vv-name="line2" placeholder="Address Line 2"
                                    autocomplete="section-business address-line2" class="form-control no-instruction"
                                    :class="{
                                      input: true,
                                      'is-invalid': errors.has('line2'),
                                      'has-invalid': errors.has('line2'),
                                    }" />
                                  <span class="has-icon-feedback"></span>
                                  <div class="invalid-feedback" :is-invalid="errors.has('line2')">
                                    {{ errors.first("line2") }}
                                  </div>
                                </b-form-group>

                              </div>
                              <div class="col-lg-6">
                                <b-form-group role="group" class="mb-3 has-feedback">
                                  <label class="w-100 form-control-label" for="website">Website</label>
                                  <input type="text" name="website" v-model.trim="business.website"
                                    data-vv-as="Business Website" v-validate="'required'" data-vv-name="website"
                                    placeholder="Business Website" autocomplete="section-business url"
                                    class="form-control no-instruction" :class="{
                                      input: true,
                                      'is-invalid': errors.has('website'),
                                      'has-invalid': errors.has('website'),
                                    }" />
                                  <span class="has-icon-feedback"></span>
                                  <div class="invalid-feedback" :is-invalid="errors.has('website')">
                                    {{ errors.first("website") }}
                                  </div>
                                </b-form-group>
                                <b-form-group role="group" class="mb-3 has-feedback">
                                  <label class="w-100 form-control-label" for="businessEmail">Business Email
                                  </label>
                                  <input type="text" name="businessEmail" v-model.trim="business.email"
                                    v-validate="'required|email'" data-vv-name="businessEmail"
                                    data-vv-as="Business Email" placeholder="Enter Business Email Address"
                                    autocomplete="section-business email" class="form-control no-instruction" :class="{
                                      input: true,
                                      'is-invalid': errors.has('businessEmail'),
                                      'has-invalid':
                                        errors.has('businessEmail'),
                                    }" />
                                  <span class="has-icon-feedback"></span>
                                  <div class="invalid-feedback" :is-invalid="errors.has('businessEmail')">
                                    {{ errors.first("businessEmail") }}
                                  </div>
                                </b-form-group>

                                <b-form-group role="group" class="mb-3 has-feedback">
                                  <label class="w-100 form-control-label" for="phone">Business Phone Number
                                  </label>
                                  <input type="text" name="businessContact" v-model.trim="business.phone"
                                    v-mask="'### ###-####'" v-validate="{
                                      required: true,
                                      min: 10,
                                      regex:
                                        /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                                    }" data-vv-name="businessContact" data-vv-as="Business Phone Number"
                                    placeholder="Enter Business Phone Number (123 123-1234)"
                                    autocomplete="section-business tel" class="form-control no-instruction" :class="{
                                      input: true,
                                      'is-invalid':
                                        errors.has('businessContact'),
                                      'has-invalid':
                                        errors.has('businessContact'),
                                    }" />
                                  <span class="has-icon-feedback"></span>
                                  <div class="invalid-feedback" :is-invalid="errors.has('businessContact')">
                                    {{ errors.first("businessContact") }}
                                  </div>
                                </b-form-group>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div id="advertisement_information">
                          <div class="form-head">
                            <h1>2. Advertisement Information</h1>
                          </div>
                          <div class="form-body">
                            <div class="row">
                              <div class="col-lg-4">
                                <b-form-group role="group" class="mb-3 has-feedback">
                                  <label class="w-100 form-control-label" for="city">Select City
                                  </label>
                                  <b-form-select v-model="advertisement.city" :options="cities" name="city"
                                    placeholder="Please select city" v-validate="'required'" data-vv-as="City"
                                    data-vv-name="city" class="
                                      form-control
                                      no-instruction
                                      custom-select
                                    " :class="{
                                      'is-invalid': errors.has('city'),
                                      'has-invalid': errors.has('city'),
                                    }">
                                  </b-form-select>
                                  <span class="has-icon-feedback"></span>
                                  <div class="invalid-feedback" :is-invalid="errors.has('city')">
                                    {{ errors.first("city") }}
                                  </div>
                                </b-form-group>

                                <b-form-group role="group" class="mb-3 has-feedback">
                                  <label class="w-100 form-control-label" for="datepicker">Select when to start
                                    advertisement
                                    campaign</label>
                                  <b-form-datepicker :min="new Date()" v-on:change="updateCheckoutInformation()"
                                    id="datepicker" value-as-date v-model="selectedDate" class="mb-2">
                                  </b-form-datepicker>
                                </b-form-group>

                                <b-form-group role="group" class="mb-3 has-feedback">
                                  <label class="w-100 form-control-label" for="adDuration">Enter advertisement
                                    duration
                                    (Months)</label>
                                  <input type="number" name="adDuration" v-on:change="updateCheckoutInformation()"
                                    v-model.trim="adDuration" data-vv-as="Advertisement Duration (Months)"
                                    v-validate="'required|min_value:0'" data-vv-name="adDuration"
                                    placeholder="Advertisement Duration (Months)" class="form-control no-instruction"
                                    :class="{
                                      input: true,
                                      'is-invalid': errors.has('adDuration'),
                                      'has-invalid': errors.has('adDuration'),
                                    }" />
                                  <span class="has-icon-feedback"></span>
                                  <div class="invalid-feedback" :is-invalid="errors.has('adDuration')">
                                    {{ errors.first("adDuration") }}
                                  </div>
                                </b-form-group>

                                <b-form-group role="group" class="mb-3 has-feedback">
                                  <label class="w-100 form-control-label" for="page">Select Page
                                  </label>
                                  <b-form-select v-model="advertisement.page" :options="pages" name="page"
                                    placeholder="Please select page" v-validate="'required'" data-vv-as="Page"
                                    data-vv-name="page" class="
                                      form-control
                                      no-instruction
                                      custom-select
                                    " :class="{
                                      'is-invalid': errors.has('page'),
                                      'has-invalid': errors.has('page'),
                                    }" @change="selectPage()">
                                  </b-form-select>
                                  <span class="has-icon-feedback"></span>
                                  <div class="invalid-feedback" :is-invalid="errors.has('page')">
                                    {{ errors.first("page") }}
                                  </div>
                                </b-form-group>
                                <b-form-group v-if="advertisement.page != null && this.advertisement.page === '602cc007e5ba430015dbc5f6'" role="group" class="mb-3 has-feedback">
                                  <div v-if="categories.length > 1">
                                    <label class="w-100 form-control-label" for="category">Select Category <br><span
                                        class="subtext">Leave blank to post on community home page</span>
                                    </label>
                                    <b-form-select v-model="advertisement.category" :options="categories"
                                      name="category" placeholder="Select category (Optional)" data-vv-as="category"
                                      data-vv-name="category" class="form-control no-instruction custom-select" @change="selectCategory()">
                                    </b-form-select>
                                  </div>
                                </b-form-group>
                                <div class="advertisement-preview mt-4 mb-4" v-if="this.advertisement.page">
                                  <img v-if="this.advertisement.page === '6038d862a7ba0d268faeadfd'" src="./previews/6038d862a7ba0d268faeadfd.png"/>
                                  <img v-if="this.advertisement.page === '602cc007e5ba430015dbc5f4'" src="./previews/602cc007e5ba430015dbc5f4.png"/>
                                  <img v-if="this.advertisement.page === '602cc007e5ba430015dbc5f5'" src="./previews/602cc007e5ba430015dbc5f5.png"/>
                                  <img v-if="this.advertisement.page === '602cc007e5ba430015dbc5f6' && !advertisement.category" src="./previews/602cc007e5ba430015dbc5f6.png"/>
                                  <img v-if="this.advertisement.page === '602cc007e5ba430015dbc5f6' && advertisement.category" src="./previews/602cc007e5ba430015dbc5f6-category.png"/>
                                </div>
                              </div>
                              <div class="col-lg-8">
                                <b-form-group role="group" class="mb-3 has-feedback">
                                  <label class="w-100 form-control-label" for="uploadImages">Upload Images</label>
                                  <b-form-file accept="image/*" v-model="urlImage" data-vv-as="Images"
                                    data-vv-name="uploadImages" v-validate="'required'" @change="onFileChange"
                                    @ondrop="onFileChange" @ondragover="onFileChange" :state="!hasImageError"
                                    placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..."
                                    class="upload-img-field" :class="{
                                      'is-invalid': hasImageError,
                                      'has-invalid': hasImageError,
                                    }"></b-form-file>
                                  <div v-if="hasImageError" class="image-error-msg">
                                    {{ imageError }}
                                  </div>
                                </b-form-group>
                                <h5 class="mt-3">
                                  <i>For best display results, make sure to
                                    upload an image with a 4:3 aspect ratio.
                                    Minimum recommended size is 1024x576</i>
                                </h5>

                                <div v-if="business.images.otherImage != 0" class="preview-images">
                                  <h2>Select Cover Image</h2>
                                  <div class="row preview-container">
                                    <div v-for="img in business.images.otherImage" :key="img.index"
                                      class="mb-5 col-lg-12">
                                      <cropper ref="cropper" class="cropper" :class="[
                                        {
                                          selected:
                                            img.index ==
                                            business.images.mainImage,
                                        },
                                      ]" :src="img.image" :stencil-props="{ aspectRatio: limitations.aspectRatio }" />


                                      <!-- <div class="select-container">
                                        <div v-if="
                                          img.index !=
                                          business.images.mainImage
                                        " class="btn btn-theme" @click="selectMainImage(img.index)">
                                          Select As Cover
                                        </div>
                                      </div> -->
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                        <div id="subscription">
                          <div class="form-head">
                            <h1>3. Select Subscription</h1>
                          </div>
                          <div class="form-body">
                            <div class="subscription-container">
                              <div v-for="subscription in advertisementPlans" :key="subscription._id" :class="[
                                'subscription-card',
                                {
                                  selectedSubscription:
                                    selectedAdvertisementPlan == subscription._id,
                                },
                              ]" @click.prevent="selectSubscription(subscription._id, subscription.price.stripe)">
                                <h3>{{ subscription.name }}</h3>
                                <p class="as-low">As low as</p>
                                <div class="price">
                                  ${{ subscription.price.price.toFixed(2) }}
                                </div>
                                <button class="select-btn">
                                  {{
                                      selectedAdvertisementPlan == subscription._id
                                        ? "Selected"
                                        : "Select Subscription"
                                  }}
                                </button>
                                <div class="description" v-html="subscription.description"></div>
                                <div v-if="
                                  selectedAdvertisementPlan == subscription._id
                                " class="note-selected">
                                  <img src="../../assets/images/checked.png" alt="" srcset="" />
                                </div>
                                <br />
                              </div>
                              <p v-if="!this.advertisement.page"><b>Must select a page to display advertisement
                                  plans</b></p>
                            </div>
                          </div>
                          <div v-if="this.advertisement.page" class="col-lg-6 promo-code">
                            <b-form-group role="group" class="mb-3 has-feedback">
                              <div class="promo-code-box">
                                <span class="promo-left">
                                  <label class="w-100 form-control-label text-center" for="promoCode">Promo Code</label>
                                  <input @input="promoCodeChanged" type="text" name="promoCode" v-model.trim="promoCode"
                                    data-vv-as="Enter Promo Code" v-validate="" data-vv-name="promoCode"
                                    placeholder="Enter Promo Code" class="form-control no-instruction text-center"
                                    :class="{
                                      input: true,
                                      'is-invalid': errors.has('promoCode'),
                                      'has-invalid': errors.has('promoCode'),
                                    }" />
                                  <span class="has-icon-feedback"></span>
                                  <div class="invalid-feedback" :is-invalid="errors.has('promoCode')">
                                    {{ errors.first("promoCode") }}
                                  </div>
                                </span>
                                <div v-if="promoCode" class="promo-right">
                                  <button v-if="!promoCodeStatus" type="button" id="processPromoCode"
                                    @click="applyPromoCode()" class="btn btn-primary text-center"
                                    :style="'font-weight: 900; color: white !important;'">
                                    <b-icon v-if="fetchingPromoCode" icon="arrow-clockwise" animation="spin"
                                      class="btn-loader" font-scale="1"></b-icon>
                                    Apply
                                  </button>
                                  <div v-else
                                    :style="'display: flex; align-items: center; column-gap: 1rem; font-weight: 900 !important; ' + (promoCodeStatus ? promoCodeStatus === 'Applied' ? 'color: var(--success) !important;' : 'color: var(--danger) !important;' : 'color: white !important;')">
                                    <b-icon v-if="promoCodeStatus==='Applied'" icon="check-circle" scale="1.5" variant="success"></b-icon> 
                                    <b-icon v-else icon="exclamation-circle-fill" scale="1.5" variant="danger"></b-icon>
                                    {{ promoCodeStatus}}
                                  </div>

                                </div>
                              </div>
                            </b-form-group>
                          </div>

                          <div style="text-align: center; margin-block: 3rem;" v-else><b>You must fill out all info to
                              checkout</b></div>

                          <b-row v-if="loading">
                            <div class="col-lg-12 text-center">
                              <pulse-loader :color="'#0cb799'"></pulse-loader>
                              Validating Advertisement
                              <br />
                              <br />
                            </div>
                          </b-row>
                          <b-row class="mt-5 mb-5" style="justify-content: center;">
                            <button type="button" :disabled="loading || !(selectedDate && adDuration)" id="validateNewUser"
                              @click="validateAd()" class="btn btn-primary text-center">
                              <b-icon icon="arrow-clockwise" animation="spin" class="btn-loader hidden" font-scale="1">
                              </b-icon>
                              Continue
                              <span class="promise-btn__spinner-wrapper hidden"><span data-v-cc3e8e04="" class="spinner"
                                  style="
                                      border-top-color: rgb(52, 152, 219);
                                      width: 18px;
                                      height: 18px;
                                      border-width: 4px;
                                      animation-duration: 1s;
                                    "></span></span>
                            </button>
                          </b-row>
                        </div>
                      </form>
                      <form class="w-100" v-if="step === 'checkout'">
                        <div id="review">
                          <div class="form-head">
                            <h1>Review & Checkout</h1>
                          </div>
                          <div class="review-message">
                            <h3 class="mb-2 text-center">Please review the information below is correct and valid.</h3>
                            <h4 class="text-center">No refunds are changes are able to be made after checkout.</h4>
                          </div>
                        </div>
                        <div id="review-business">
                          <div class="form-head">
                            <h1>Business Information</h1>
                          </div>
                          <div class="business-review">
                            <div class="label">Business Name:</div>
                            <div class="value">{{business.name}}</div>
                            <div class="label">Address Line 1:</div>
                            <div class="value">{{business.location.address.line1}}</div>
                            <div class="label">Address Line 2:</div>
                            <div class="value">{{business.location.address.line2 || " "}}</div>
                            <div class="label">Website:</div>
                            <div class="value">{{business.website}}</div>
                            <div class="label">Email:</div>
                            <div class="value">{{business.email}}</div>
                            <div class="label">Phone:</div>
                            <div class="value">{{business.phone}}</div>
                          </div>
                        </div>
                        <div id="review-advertisement">
                          <div class="form-head">
                            <h1>Advertisement Preview</h1>
                          </div>
                          <div class="advertisement-preview mt-4 mb-4" v-if="this.advertisement.page">
                            <img v-if="this.advertisement.page === '6038d862a7ba0d268faeadfd'" src="./previews/6038d862a7ba0d268faeadfd.png"/>
                            <img v-if="this.advertisement.page === '602cc007e5ba430015dbc5f4'" src="./previews/602cc007e5ba430015dbc5f4.png"/>
                            <img v-if="this.advertisement.page === '602cc007e5ba430015dbc5f5'" src="./previews/602cc007e5ba430015dbc5f5.png"/>
                            <img v-if="this.advertisement.page === '602cc007e5ba430015dbc5f6' && !advertisement.category" src="./previews/602cc007e5ba430015dbc5f6.png"/>
                            <div v-if="this.advertisement.page === '602cc007e5ba430015dbc5f6' && advertisement.category" style="position: relative">
                              <div class="preview-category" id="categoryReview">{{this.categories.find(c => c.value === advertisement.category).text}}</div>
                              <img src="./previews/602cc007e5ba430015dbc5f6-category.png"/>
                            </div>
                            <div class="preview-image" :style="this.previewBaseCoordinates[this.imagePreviewName]">
                              <img :src="adPreviewImage" />
                            </div>
                          </div>
                        </div>
                        <div id="review-checkout">
                          <div class="form-head">
                            <h1>Checkout</h1>
                          </div>
                          <div class="review-body">
                            <h1>XB Advertisement Posting</h1>
                            <div class="review-grid">
                              <div class="mini-grid white-line">
                                <div class="bold">Start Date</div>
                                <div class="text-align-center grey-text">{{
                                    formatDate(false)
                                }}</div>
                                <div class="bold text-align-right x-large-text">
                                  ${{ checkoutInformation.preTotalOverTerm }}</div>
                                <div class="bold">End Date</div>
                                <div class="text-align-center grey-text">{{
                                    formatDate(true)
                                }}</div>
                                <div class="text-align-right grey-text">
                                  ${{ checkoutInformation.preTotal }} x {{ adDuration }}
                                  months</div>
                              </div>
                              <div v-if="promoCodeStatus === 'Applied'" class="mini-grid white-line">
                                <div class="medium-text red-text bold">Promo Code</div>
                                <div class="small-text medium-text text-align-center red-text">{{
                                    promoCode
                                }}</div>
                                <div>
                                  <div class="red-text text-align-right medium-text">
                                    (${{ checkoutInformation.promoCodeDiscountDollarsOverTerm }})</div>
                                  <div
                                    class="small-text red-text text-align-right">
                                    {{ checkoutInformation.promoCodeDiscountDollarsText }}</div>
                                </div>
                              </div>
                              <div class="mini-grid white-line2">
                                <div class="x-large-text bold">Subtotal</div>
                                <div class=""></div>
                                <div class="bold xx-large-text text-align-right">
                                  ${{ checkoutInformation.subtotal }}</div>
                              </div>
                              <div class="mini-grid white-line3">
                                <div class="bold">Tax</div>
                                <div class="text-align-center grey-text">Taxes & Fees Included</div>
                                <div class=""></div>
                              </div>
                              <div class="mini-grid">
                                <div class="x-large-text">Total</div>
                                <div class=""></div>
                                <div class="xx-large-text text-align-right">${{ checkoutInformation.total
                                }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <b-row v-if="loading">
                            <div class="col-lg-12 text-center">
                              <pulse-loader :color="'#0cb799'"></pulse-loader>
                              Redirecting to Checkout
                              <br />
                              <br />
                            </div>
                          </b-row>
                          <b-row class="mt-5 mb-5" style="justify-content: center;">
                            <button type="button" id="back"
                              @click="back()" class="btn btn-primary btn-outline text-center">
                              Back
                              <span class="promise-btn__spinner-wrapper hidden"><span class="spinner"
                                  style="
                                      border-top-color: rgb(52, 152, 219);
                                      width: 18px;
                                      height: 18px;
                                      border-width: 4px;
                                      animation-duration: 1s;
                                    "></span></span>
                            </button>
                            <button type="button" id="processNewUser"
                              @click="registerAd()" class="btn btn-primary text-center">
                              <b-icon icon="arrow-clockwise" animation="spin" class="btn-loader hidden" font-scale="1">
                              </b-icon>
                              Checkout
                              <span class="promise-btn__spinner-wrapper hidden"><span class="spinner"
                                  style="
                                      border-top-color: rgb(52, 152, 219);
                                      width: 18px;
                                      height: 18px;
                                      border-width: 4px;
                                      animation-duration: 1s;
                                    "></span></span>
                            </button>
                          </b-row>
                        </div>
                      </form>
                    </b-row>
                  </b-card-body>
                </b-card>
              </div>
            </b-row>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import * as Cookies from "js-cookie";
import { Validator } from "vee-validate";
import { loadStripeCheckout } from "../../utils/loadStripe";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import { getCoupon } from '@/api/request/subscriptions'
import { createCheckoutSection } from '@/api/request/advertisement'

Vue.use(Cookies);
import { globalStore } from '@/utils/global'
export default {
  components: {
    Cropper,
  },
  data() {
    return {
      step: 'register',
      fetchingPromoCode: false,
      promoCodeStatus: null,
      promoCode: null,
      promoCodeInfo: null,
      mediaQuery: null,
      selectedAdvertisementPlan: null,
      stripe: undefined,
      card: undefined,
      cardInput: undefined,
      checkoutInformation: {
        total: null,
        subtotal: null,
        promoCodeDiscountDollars: null,
        promoCodeDiscountDollarsText: null,
        promoCodeDiscountDollarsOverTerm: null,
        preTotal: null,
        preTotalOverTerm: null,
        readyForCheckout: false,
      },
      advertisementPlans: null,
      business: {
        name: "",
        location: {
          address: {
            line1: "",
            line2: "",
          },
        },
        website: "",
        email: "",
        phone: "",
        images: {
          mainImage: 0,
          otherImage: [],
        },
      },
      advertisement: {
        city: null,
        page: null,
        category: null,
      },
      adPreviewImage: null,
      isLoading: false,
      selectedDate: null,
      adDuration: null,
      urlImage: [],
      action: "create",

      phone: "",
      email: "",
      password: "",
      role: null,
      disableSubmitButton: true,
      profileLoaded: false,
      resetProfileData: {},
      passwordFieldType: {
        password: "password",
      },
      iconType: {
        password: "far fa-eye",
      },
      progressWidth: 0,
      progressLabel: "",
      hasNumber: false,
      hasUpperCase: false,
      hasLowerCase: false,
      hasLowercaseUppercase: false,
      hasSpecialChar: false,
      hasPeriodDash: false,
      hasError: false,
      changePasswordError: "",
      passwordShow: false,
      formData: new FormData(),
      communityCategory: null,
      imageError: "",
      hasImageError: false,
      hasCardError: false,
      cardError: "",
      cropperImages: [],
      limitations: {
        maxWidth: 1024,
        maxHeight: 576,
        minWidth: 0,
        minHeight: 0,
        aspectRatio: 4 / 3,
      },
      emailIsExist: false,
      allowedImages: 1,
      selectedPlan: null,
      validFormData: null
    };
  },
  computed: {
    cities() {
      let data = [];
      this.cityList.map((city) => {
        data.push({ value: city._id, text: city.city });
      });

      return [
        { value: null, text: "Please select city", disabled: true },
        ...data,
      ];
    },
    cityList: {
      get: function () {
        return this.$store.state.cities.cities;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    pages() {
      return [
        { text: "Please select page", value: null, disabled: true },
        { text: "Home", value: "6038d862a7ba0d268faeadfd" },
        { text: "Culture", value: "602cc007e5ba430015dbc5f4" },
        { text: "Connection", value: "602cc007e5ba430015dbc5f5" },
        { text: "Community", value: "602cc007e5ba430015dbc5f6" },
      ];
    },
    previewBaseCoordinates() {
      return {
        "6038d862a7ba0d268faeadfd": {
          left: '7.8%',
          top: '22.7%',
          width: '84.4%',
          height: '31.5%'
        },
        "602cc007e5ba430015dbc5f4": {
          left: '7.8%',
          top: '20.86%',
          width: '84.4%',
          height: '31.5%'
        },
        "602cc007e5ba430015dbc5f5": {
          left: '7.8%',
          top: '20.86%',
          width: '84.4%',
          height: '31.5%'
        },
        "602cc007e5ba430015dbc5f6": {
          left: '7.8%',
          top: '20.86%',
          width: '84.4%',
          height: '31.5%'
        },
        "602cc007e5ba430015dbc5f6-category": {
          left: '7.8%',
          top: '20.86%',
          width: '84.4%',
          height: '31.5%'
        },
      };
    },
    imagePreviewName() {
      return `${this.advertisement.page}${this.advertisement.page === '602cc007e5ba430015dbc5f6' && this.advertisement.category ? '-category' : ''}`
    },
    categories() {
      let data = [];
      this.categoryList.map((category) => {
        data.push({ value: category._id, text: category.title });
      });

      return [
        { value: null, text: "Select a Category (Optional)", disabled: true },
        { value: '', text: "Landing Page" },
        ...data,
      ];
    },
    categoryList: {
      get: function () {
        return this.$store.state.advertisement.advertisementCategories;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    loading() {
      return this.isLoading;
    },
    adminUserList() {
      return this.$store.state.admin.userList.all || null;
    },
  },
  methods: {
    async applyPromoCode() {
      this.fetchingPromoCode = true;
      const token = Cookies.get('user-token') || globalStore.token
      try {
        this.promoCodeInfo = await getCoupon(token, this.promoCode);
        this.promoCodeStatus = 'Applied';
        this.promoCodeInfo = this.promoCodeInfo.data.coupon_response;
      } catch {
        this.promoCodeStatus = 'Invalid';
      }
      this.fetchingPromoCode = false;
      this.updateCheckoutInformation();
    },
    promoCodeChanged() {
      this.promoCodeStatus = null;
      this.promoCodeInfo = null;
      this.updateCheckoutInformation();
    },
    formatDate(isEnd) {
      if (this.selectedDate && this.adDuration) {
        let date = new Date(this.selectedDate)
        if (isEnd) {
          date.setMonth(date.getMonth() + Number(this.adDuration));
        }
        let year = date.getFullYear();
        const month = date.toLocaleString('default', { month: 'long' });
        let day = date.getDate().toString().padStart(2, '0');
        return month + ' ' + day + ', ' + year;
      } else {
        return "No date selected";
      }
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    selectPage() {
      this.advertisement.category = null;
      this.getCategories();
      this.getAdvertisementPlans(this.advertisement.page, false);
    },
    selectCategory() {
      this.getAdvertisementPlans(this.advertisement.page, this.advertisement.category);
    },
    selectMainImage(index) {
      this.business.images.mainImage = index;
    },
    onFileChange(e) {
      const { files } = e.target;
      const self = this;

      this.hasImageError = false;

      if (files.length > this.allowedImages) {
        self.business.images.otherImage = [];
        self.urlImage = [];

        return self.$notify({
          message: `You can only upload upto ${this.allowedImages} image(s).`,
          timeout: 4000,
          icon: "fas fa-exclamation-triangle",
          type: "danger",
        });
      }

      self.business.images.otherImage = [];
      self.business.images.mainImage = undefined;

      for (var i = 0; i < files.length; i++) {
        self.business.images.otherImage.push({
          index: i,
          fileName: files[i].name,
          image: URL.createObjectURL(files[i]),
        });
        self.formData.append("images", files[i]);
      }
      self.business.images.mainImage = 0;
    },
    passwordCheck: function () {
      const password = this.password;
      this.hasNumber = /\d/.test(password);
      this.hasUpperCase = /[A-Z]/.test(password);
      this.hasLowerCase = /[a-z]/.test(password);
      this.hasLowercaseUppercase =
        /[a-z]/.test(password) && /[A-Z]/.test(password);
      this.hasSpecialChar = /[!@#/$%^&*)(+=#><;:,._-]/.test(password);
      this.hasPeriodDash = !/^\s*?\.|-.*$/.test(password);
      this.level = 0;

      var progressBar = document.querySelectorAll(".passwordMeter");
      var charCounts = {};
      var numCount = 0;
      var upperCaseCount = 0;
      var specialCharCount = 0;

      for (var i = 0; i < password.length; i++) {
        if (/\d/.test(password.charAt(i))) numCount++;
        if (/[A-Z]/.test(password.charAt(i))) upperCaseCount++;
        if (/[!@#/$%^&*)(+=#><;:,._-]/.test(password.charAt(i)))
          specialCharCount++;
      }

      charCounts.numCount = numCount;
      charCounts.ucCount = upperCaseCount;
      charCounts.scCount = specialCharCount;

      this.progressWidth = this.calcPassStrength(
        password.length,
        this.hasNumber,
        this.hasLowercaseUppercase,
        charCounts,
        this.hasSpecialChar,
        this.hasPeriodDash
      );

      if (this.progressWidth <= 40) {
        [].forEach.call(progressBar, function (el) {
          el.classList.remove("bg-warning");
          el.classList.remove("bg-success");
          el.classList.add("bg-danger");
        });
        this.progressLabel = this.progressWidth >= 30 ? "Bad Password" : "Bad";
        this.$validator.errors.add({
          field: "password",
          msg: "Password must meet the criteria",
          scope: "account",
        });
      } else if (this.progressWidth > 40 && this.progressWidth < 75) {
        [].forEach.call(progressBar, function (el) {
          el.classList.remove("bg-danger");
          el.classList.remove("bg-success");
          el.classList.add("bg-warning");
        });
        this.progressLabel = "Weak Password";
        this.$validator.errors.add({
          field: "password",
          msg: "Password must meet the criteria",
          scope: "account",
        });
      } else if (this.progressWidth >= 75) {
        [].forEach.call(progressBar, function (el) {
          el.classList.remove("bg-warning");
          el.classList.remove("bg-danger");
          el.classList.add("bg-success");
        });
        this.progressLabel = "Strong Password";

        this.$nextTick(() => {
          this.$validator.errors.remove("password");
        });
      }
    },
    calcPassStrength: function (
      len,
      num,
      lowCap,
      charCounts,
      specialChar
    ) {
      var level = 0;
      if (len >= 8) {
        level += 18;
      }
      if (charCounts.ucCount === 2) {
        level += 10;
      }
      if (charCounts.ucCount > 2) {
        level += 4;
      }
      if (num) {
        level += 12;
      }
      if (charCounts.numCount > 2) {
        level += 5;
      }
      if (lowCap) {
        level += 22;
      }
      if (specialChar) {
        level += 23;
      }
      if (charCounts.scCount === 2) {
        level += 10;
      }
      if (charCounts.scCount >= 3) {
        level += 12;
      }
      if (len === 0) {
        level = 0;
      }
      return level;
    },
    async validateAd() {
      const promiseBtn = document.getElementById("validateNewUser");
      const promiseBtnInner = promiseBtn.querySelector(
        ".promise-btn__spinner-wrapper"
      );
      const btnLoader = promiseBtn.querySelector(".btn-loader");
      btnLoader.classList.remove("hidden");
      promiseBtnInner.classList.remove("hidden");
      promiseBtn.setAttribute("disabled", "disabled");

      const bodyWrapper = document.getElementById("processAdminForm");
      bodyWrapper.classList.add("non-clickable");


      if (this.business.images.otherImage.length == 0) {
        this.hasImageError = true;
        this.imageError = "Please upload images";
      }

      return this.$validator.validateAll().then(async (isValid) => {
        this.validFormData = null
        if (isValid) {

          let user = {
            business: this.business,
            advertisement: this.advertisement,
            subscription: this.selectedAdvertisementPlan,
            plan: this.selectedPlan,
            promoCode: this.promoCodeStatus == 'Applied' ? this.promoCode : null,
            selectedDate: this.selectedDate,
            adDuration: this.adDuration,
            preview_coordinates: {}
          };

          const formData = new FormData()

          // Send Cropper Image to API
          for (var i = 0; i < this.business.images.otherImage.length; i++) {
            const { canvas, coordinates } = this.$refs.cropper[i].getResult();
            const cover = this.dataURLtoFile(
              canvas.toDataURL(),
              this.business.images.otherImage[i].fileName
            );
            user.preview_coordinates = coordinates;
            formData.append("images_preview", cover);

            this.adPreviewImage = canvas.toDataURL()
          }

          formData.set("ad", JSON.stringify(user));
          this.isLoading = true;
          this.$store
            .dispatch("advertisement/VALIDATE_ADVERTISEMENT", formData)
            .then((res) => {
              console.log(res)
              if (res.data.success) {
                window.scrollTo(0, 0) 
                this.step = 'checkout'
                this.isLoading = false;
                setTimeout(this.updateCategoryPreviewText, 500)
                this.validFormData = formData
              } else {
                // THESE ARE NOT THE CORRECT ERRORS
                if (res.data.error === "error_user_already_exist") {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      "Email already in use"
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                } else if (res.data.error === "Error_creating_business") {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      "Business name already in use"
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                } else {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      "Error creating user"
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                }
              }
              this.isLoading = false;
              promiseBtnInner.classList.add("hidden");
              promiseBtn.removeAttribute("disabled", "disabled");
              bodyWrapper.classList.remove("non-clickable");
            })
            .catch((err) => {
              console.log("VALIDATION_ERR:", err);
              err.then((error) => {
                console.log("THEN ERROR:", { error });
                if (error.errMessage === "Email already exist.") {
                  this.emailIsExist = true;
                }
                if (error.errMessage == "error_creating_business") {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      "Business name already in use!"
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                  this.hasError = true;
                  this.isLoading = false;
                  promiseBtnInner.classList.add("hidden");
                  promiseBtn.removeAttribute("disabled", "disabled");
                  bodyWrapper.classList.remove("non-clickable");
                } else {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      error.errMessage || ''
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                  this.hasError = true;
                  this.isLoading = false;
                  promiseBtnInner.classList.add("hidden");
                  promiseBtn.removeAttribute("disabled", "disabled");
                  bodyWrapper.classList.remove("non-clickable");
                }
              });
            });
        } else {
          this.$notify({
            message: this.$options.filters.capitalizeWords(
              "Please complete all required fields."
            ),
            timeout: 4000,
            icon: "fas fa-exclamation-triangle",
            type: "danger",
          });
          this.isLoading = false;
          btnLoader.classList.add("hidden");
          promiseBtnInner.classList.add("hidden");
          promiseBtn.removeAttribute("disabled", "disabled");
          bodyWrapper.classList.remove("non-clickable");
        }
      });
    },
    async back() {
      this.step = 'register';
      this.validFormData = null;
    },
    async registerAd() {
      const promiseBtn = document.getElementById("processNewUser");
      const promiseBtnInner = promiseBtn.querySelector(
        ".promise-btn__spinner-wrapper"
      );
      const btnLoader = promiseBtn.querySelector(".btn-loader");
      btnLoader.classList.remove("hidden");
      promiseBtnInner.classList.remove("hidden");
      promiseBtn.setAttribute("disabled", "disabled");

      const bodyWrapper = document.getElementById("processAdminForm");
      bodyWrapper.classList.add("non-clickable");

      if (this.validFormData) {
        this.isLoading = true;

        this.$store
          .dispatch("advertisement/REGISTER_ADVERTISEMENT", this.validFormData)
          .then((res) => {
            if (res.data.success && res.data.redirectUrl) {
              window.location.href = res.data.redirectUrl
              this.isLoading = false;
            } else {
              // THESE ARE NOT THE CORRECT ERRORS
              if (res.data.error === "error_user_already_exist") {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    "Email already in use"
                  ),
                  timeout: 4000,
                  icon: "fas fa-exclamation-triangle",
                  type: "danger",
                });
              } else if (res.data.error === "Error_creating_business") {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    "Business name already in use"
                  ),
                  timeout: 4000,
                  icon: "fas fa-exclamation-triangle",
                  type: "danger",
                });
              } else {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    "Error creating user"
                  ),
                  timeout: 4000,
                  icon: "fas fa-exclamation-triangle",
                  type: "danger",
                });
              }
            }
            this.isLoading = false;
            promiseBtnInner.classList.add("hidden");
            promiseBtn.removeAttribute("disabled", "disabled");
            bodyWrapper.classList.remove("non-clickable");
          })
          .catch((err) => {
            console.log("REGISTRATION_ERR:", err);
            err.then((error) => {
              console.log("THEN ERROR:", { error });
              if (error.errMessage === "Email already exist.") {
                this.emailIsExist = true;
              }
              if (error.errMessage == "error_creating_business") {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    "Business name already in use!"
                  ),
                  timeout: 4000,
                  icon: "fas fa-exclamation-triangle",
                  type: "danger",
                });
                this.hasError = true;
                this.isLoading = false;
                promiseBtnInner.classList.add("hidden");
                promiseBtn.removeAttribute("disabled", "disabled");
                bodyWrapper.classList.remove("non-clickable");
              } else {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    error.errMessage || ''
                  ),
                  timeout: 4000,
                  icon: "fas fa-exclamation-triangle",
                  type: "danger",
                });
                this.hasError = true;
                this.isLoading = false;
                promiseBtnInner.classList.add("hidden");
                promiseBtn.removeAttribute("disabled", "disabled");
                bodyWrapper.classList.remove("non-clickable");
              }
            });
          });
      } else {
        this.$notify({
          message: this.$options.filters.capitalizeWords(
            "Please complete all required fields."
          ),
          timeout: 4000,
          icon: "fas fa-exclamation-triangle",
          type: "danger",
        });
        this.isLoading = false;
        this.back();
        btnLoader.classList.add("hidden");
        promiseBtnInner.classList.add("hidden");
        promiseBtn.removeAttribute("disabled", "disabled");
        bodyWrapper.classList.remove("non-clickable");
      }
    },
    updateCategoryPreviewText() {
      if (this.step == 'checkout' && this.advertisement.category) {
        var categoryReview = document.getElementById('categoryReview');
        if (categoryReview) {
          categoryReview.style.fontSize = (categoryReview.clientHeight * 0.75) + 'px'
        }
      }
    },
    clearFields() {
      this.$validator.reset();
      this.errors.clear();
      this.oldPassword =
        this.password =
        this.confirmPassword =
        this.changePasswordError =
        this.progressLabel =
        "";
      this.progressWidth = 0;
      this.hasNumber =
        this.hasUpperCase =
        this.hasLowerCase =
        this.hasLowercaseUppercase =
        this.hasSpecialChar =
        this.hasPeriodDash =
        this.hasError =
        false;
      this.cancelUpdateAccount();
    },
    switchVisibility(field) {
      this.passwordFieldType[field] =
        this.passwordFieldType[field] === "password" ? "text" : "password";
      this.iconType[field] =
        this.iconType[field] === "far fa-eye"
          ? "far fa-eye-slash"
          : "far fa-eye";
    },
    selectSubscription(subscription, plan_id) {
      if (subscription !== this.selectedAdvertisementPlan) {
        this.selectedAdvertisementPlan = subscription;
        this.selectedPlan = plan_id;
        this.updateCheckoutInformation()
      }
    },
    getCategories() {
      if(this.advertisement.page === '602cc007e5ba430015dbc5f6')
      this.$store.dispatch("advertisement/GET_ADVERTISEMENT_CATEGORY_LIST", {
        query: {
          page: this.advertisement.page,
          show: true
        },
      });
    },
    getCities() {
      this.$store.dispatch("cities/GET_CITIES", { city: "All" });
    },
    getAdvertisementPlans(page, hasCategory) {
      this.$store
        .dispatch("subscriptions/GET_ADVERTISEMENTPLANS", { isActive: 1, hidden: 0, page: page, categoryPage: hasCategory ? true : false})
        .then((resp) => {
          this.advertisementPlans = resp;
          this.selectedAdvertisementPlan = this.advertisementPlans[0]._id;
          this.selectedPlan = this.advertisementPlans[0].price.stripe
          this.allowedImages = 1;
          this.updateCheckoutInformation()
        });
    },
    updateCheckoutInformation() {
      if (this.advertisementPlans) {
        const match = this.advertisementPlans.find(x => x._id === this.selectedAdvertisementPlan);
        this.checkoutInformation.preTotal = match.price.price;
        this.checkoutInformation.preTotalOverTerm = match.price.price * this.adDuration;
        if (this.promoCodeInfo) {
          if (this.promoCodeInfo.amount_off) {
            this.checkoutInformation.promoCodeDiscountDollars = this.promoCodeInfo.amount_off / 100;
          this.checkoutInformation.promoCodeDiscountDollarsOverTerm = this.checkoutInformation.promoCodeDiscountDollars;
            this.checkoutInformation.promoCodeDiscountDollarsText = '';
          } else if (this.promoCodeInfo.percent_off) {
            this.checkoutInformation.promoCodeDiscountDollars = this.promoCodeInfo.percent_off / 100 * this.checkoutInformation.preTotal;
            this.checkoutInformation.promoCodeDiscountDollarsOverTerm = this.checkoutInformation.promoCodeDiscountDollars * this.adDuration;
            this.checkoutInformation.promoCodeDiscountDollarsText = `${this.promoCodeInfo.percent_off}% off`;
          } else {
            this.checkoutInformation.promoCodeDiscountDollarsText = '';
          }
        } else {
          this.checkoutInformation.promoCodeDiscountDollars = 0;
          this.checkoutInformation.promoCodeDiscountDollarsOverTerm = 0;
          this.checkoutInformation.promoCodeDiscountDollarsText = '';
        }
        this.checkoutInformation.subtotal = this.checkoutInformation.preTotalOverTerm - this.checkoutInformation.promoCodeDiscountDollarsOverTerm;
        if (this.checkoutInformation.subtotal < 0) {
          this.checkoutInformation.subtotal = 0;
        }
        this.checkoutInformation.total = this.checkoutInformation.subtotal;
      }
    },
  },
  watch: {
    password(value) {
      if (value.length > 0) {
        this.passwordShow = true;
      } else {
        this.passwordShow = false;
      }
    },
  },
  mounted() {
    this.mediaQuery = (window.matchMedia('(max-width: 800px)')).matches;
    this.getCities();
    const stripePk =
      process.env.NODE_ENV == "development" || process.env.NODE_ENV == "staging"
        ? `${process.env.VUE_APP_STRIPE_PK_TEST_KEY}`
        : `${process.env.VUE_APP_STRIPE_PK_LIVE_KEY}`;
    const style = {
      base: {
        color: "#8898aa",
        fontFamily: '"Avenir Book", sans-serif',
        fontSize: "15px",
      },
    };

    if (process.env.NODE_ENV == "development") {
      this.business.name = 'HiloLabs'
      this.business.website = 'https://hilolabs.com'
      this.business.location.address.line1 = '123 Test St'
      this.business.email = `connor+xd.ad${(Math.random() * 10000).toFixed(0)}.dev@hilolabs.com`
      this.business.phone = '123 123-1234'
      this.adDuration = 1
      this.advertisement.city = '605896688ecdb21f0c3d9cad'
      this.advertisement.page = '602cc007e5ba430015dbc5f6'
      this.selectPage()
      this.selectedDate = new Date(Date.now())
    }

    addEventListener('resize', (event) => {
      this.updateCategoryPreviewText()
    });
  },
};
</script>

<style lang="scss" scoped>
.promo-right {
  margin-block: 1rem
}

.custom-header-bg {
  background: var(--primary) !important;
  opacity: 1;
  border-bottom: 10px solid var(--primary);
}

.custom-header {
  img {
    width: 90%;
    max-width: 500px;
    margin: auto;
  }
}

.form-body {
  padding: 20px;

  label {
    color: black;
  }

  input {
    background: transparent;
    border: none;
    border-bottom: 2px solid black;
    color: black !important;

    &::placeholder {
      color: grey !important;
    }

    &:focus {
      background: transparent;
      border-color: var(--primary);
    }
  }

  select {
    background: transparent;
    border: none;
    border-bottom: 2px solid black;
    color: grey !important;

    &::placeholder {
      color: grey !important;
    }

    &:focus {
      background: transparent;
      border-color: var(--primary);
    }
  }

  textarea {
    background: transparent;
    border: none;
    border-bottom: 2px solid black;
    color: black !important;

    &::placeholder {
      color: grey !important;
    }

    &:focus {
      background: transparent;
      border-color: var(--primary);
    }
  }
}

.form-head {
  background: var(--primary);
  padding: 5px 15px 3px;

  h1 {
    color: white;
  }
}

#review {
  background: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  .form-head {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background: var(--primary);
    border: 2px solid white;
    padding: 5px 15px 3px;

    h1 {
      color: white;
    }
  }
}

#business_information {
  background: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  .form-head {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background: var(--primary);
    border: 2px solid white;
    padding: 5px 15px 3px;

    h1 {
      color: white;
    }
  }

  .form-body {
    .custom-file-label {
      border: none;
      border-bottom: 2px solid black;
    }

    .preview-images {
      h2 {
        padding: 20px 0;
        font-size: 0.875rem;
        color: black !important;
      }

      .img-container {
        position: relative;
        width: 200px;
        // height: 200px;

        img {
          cursor: pointer;
        }

        .selected {
          border: 3px solid var(--primary);

          &:after {
            background-image: url("../../assets/images/checked.png");
            background-size: 20px 20px;
            background-repeat: no-repeat;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 10px;
            right: 10px;
            content: "";
          }

          img {
            display: block;
            margin: auto;
          }
        }
      }
    }
  }
}

.promo-code-box {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.promo-code {
  margin: auto;
}

.promo-left {
  width: 100%;
}

#user_account {
  background: white;

  .form-head {
    background: var(--primary);
    padding: 5px 15px;

    h1 {
      color: white;
    }
  }

  .form-body {
    input {
      &:focus {
        border-color: var(--primary);
      }
    }
  }
}

#subscription {
  background: white;

  .form-head {
    background: var(--primary);
    padding: 5px 15px;

    h1 {
      color: white;
    }
  }

  .form-body {
    .subscription-container {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .subscription-card {
        padding: 30px 30px 10px;
        height: auto;
        width: 400px;
        border: 1px solid white;
        background: var(--primary) !important;
        color: white;

        h3 {
          width: 85%;
          min-height: 75px;
          color: white;
          font-size: 25px;
          font-weight: 900;
        }

        p {
          margin-top: 20px;
          margin-bottom: -10px;
        }

        .as-low {
          margin-top: 10px;
        }

        .price {
          font-size: 40px;
          font-weight: 900;

          span {
            font-size: 20px;
            font-weight: 200;
          }
        }

        .trial {
          width: 100%;
          font-weight: 600;
          margin-top: 10px;
        }

        .select-btn {
          display: block;
          margin: 20px auto;
          width: 100%;
          height: 40px;
          border-radius: 5px;
          color: var(--primary-contrast);
          background: var(--primary);
          border: 1px solid var(--primary-contrast);
          font-weight: 900;
        }

        .description {
          list-style: armenian !important;
        }

        &.selectedSubscription {
          position: relative;

          h3 {
            //color: var(--primary);
          }

          .select-btn {
            background: var(--secondary);
            color: var(--secondary-contrast);

            &:focus {
              outline-color: black;
              border: none;
            }
          }

          // border: 5px solid var(--primary);
        }

        .note-selected {
          img {
            position: absolute;
            top: 20px;
            right: 20px;
            display: block;
            width: 40px;
          }
        }
      }
    }
  }
}

#advertisement_information {
  background: white;

  .form-head {
    background: var(--primary);
    padding: 5px 15px;

    h1 {
      color: white;
    }
  }

  .form-body {
    .custom-file-label {
      border: none;
      border-bottom: 2px solid black;
    }

    .preview-images {
      h2 {
        padding: 20px 0;
        font-size: 0.875rem;
        color: black !important;
      }

      .img-container {
        position: relative;
        width: 200px;
        // height: 200px;

        img {
          cursor: pointer;
        }

        .selected {
          border: 3px solid var(--primary);

          &:after {
            background-image: url("../../assets/images/checked.png");
            background-size: 20px 20px;
            background-repeat: no-repeat;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 10px;
            right: 10px;
            content: "";
          }

          img {
            display: block;
            margin: auto;
          }
        }
      }
    }
  }
}

#card_information {
  background: white;

  .form-head {
    background: var(--primary);
    padding: 5px 15px;

    h1 {
      color: white;
    }
  }
}

// Override

.subtext {
  font-size: smaller;
  color: #adb5bd;
  font-style: italic;
}

.card-body {
  padding: 0 !important;
}

.row {
  padding: 0;
  margin: 0;
}

.remove-padding {
  padding: 0;
}

.minHeight {
  margin-top: -57px !important;
}

.card {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

input[type="text"].form-control,
input[type="email"].form-control,
input[type="password"].form-control {
  color: black !important;
}

.password-validator-text {
  color: black !important;
}

#card-element {
  background: transparent !important;
  border: none;
  border-bottom: 2px solid black;
  color: green !important;
}

h2 {
  color: white;
}

h2.card-note {
  color: #525f7f;
}

// #processPromoCode,
// #validateNewUser,
// #processNewUser {
//   // background: var(--primary);
//   display: block;
//   margin: auto;
//   // border: var(--primary);
// }

.error-msg {
  color: red;
}

.image-error-msg {
  padding: 10px;
  font-size: 80%;
  font-weight: 900;
  color: #f4516c;
}

.card-error-msg {
  padding: 10px;
  font-size: 80%;
  color: #f4516c;
}

.state-err-icon {
  bottom: 58px !important;
  right: 38px;
}

.show-pass-icon {
  position: absolute;
  bottom: 15px;
  right: 30px;
}

.non-clickable {
  pointer-sponsors: none;
}

hr.divider {
  border-top: 2px dashed var(--primary);
}

h3.divider-title {
  text-transform: uppercase;
  text-align: center;
  color: black;
  margin-bottom: 25px;
}

.advertisement-preview {
  width: 90%;
  max-width: 500px;
  margin: auto;
  position: relative;

  img {
    width: 100%;
  }

  .preview-image {
    overflow: hidden;
    position: absolute;
    background-color: black;
    box-shadow: 0 0 10px 2px black;
  
    img {
      height: 100%;
      width: auto;
    }
  }

  .preview-category {
    position: absolute; 
    text-align: center;
    width: 100%;
    color: white;
    top: 7.6%;
    height: 3.5%;
    // font-size: 1rem;
  }
}

// @media only screen and (max-width: 1199px) {
//   .advertisement-preview {
//     .preview-category {
//       font-size: 0.85rem;
//     }
//   }
// }

// @media only screen and (max-width: 991px) {
//   .advertisement-preview {
//     .preview-category {
//       font-size: 1.9rem;
//     }
//   }
// }

// @media only screen and (max-width: 767px) {
//   .advertisement-preview {
//     .preview-category {
//       font-size: 1.3rem;
//     }
//   }
// }

// @media only screen and (max-width: 460px) {
//   .advertisement-preview {
//     .preview-category {
//       font-size: 1rem;
//     }
//   }
// }

// @media only screen and (max-width: 375px) {
//   .advertisement-preview {
//     .preview-category {
//       font-size: 0.8rem;
//     }
//   } 
// }

@media only screen and (max-width: 600px) {
  .subscription-card {
    padding: 25px 30px !important;
    width: 100% !important;

    h3 {
      width: 80%;
    }

    p {
      margin-bottom: 0px !important;
    }

    .price {
      font-size: 28px !important;
    }

    .note-selected {
      img {
        top: 28px !important;
        right: 28px !important;
      }
    }

    // .advertisement-preview {
    //   .preview-category {
    //     font-size: 2rem;
    //   }
    // }
  }

  .custom-header {
    img {
      width: 100%;
    }
  }

  #subscription {
    .form-body {
      overflow: auto;

      .subscription-container {
        flex-direction: column;

        .subscription-card {
          margin-bottom: 20px;
          height: auto;
        }
      }
    }
  }

  .form-container {
    padding: 0 !important;
  }
}

figure {
  margin: 0 !important;

  img {
    width: 100% !important;
  }
}

.cropper {
  &.selected {
    border: 5px solid var(--primary);

    &:after {
      background-image: url("../../assets/images/checked.png");
      background-size: 40px 40px;
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
      position: absolute;
      top: 10px;
      right: 10px;
      content: "";
    }

    img {
      display: block;
      margin: auto;
    }
  }
}

.btn-theme {
  background: var(--primary);
  margin: 15px auto;
  display: block;
  color: white;
  width: 150px;
}

.btn-loader {
  font-size: 20px;

  &.hidden {
    display: none;
  }
}

#review {
  background: white;

  .form-head {
    background: var(--primary);
    padding: 5px 15px;

    h1 {
      color: white;
    }
  }

  .white-text {
    color: black !important;
  }

  .review-message {
    margin-top: 2rem;
    padding: 20px;
    margin-bottom: 2rem;
  }


  @media only screen and (max-width: 760px) {
    .review-message {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
  
  @media only screen and (max-width: 500px) {
    .review-message {
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }
  }
}

#review-business {
  .business-review {
    margin-block: 5%;
    margin-inline: 15%;
    background-color: white;
    color: black;
    padding: 3% 3% 3% 3%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 30px;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;

    .label {
      font-size: 1.2rem;
      font-weight: bold;
    }
    .value {
      font-size: 1.2rem;
    }
  }

  @media only screen and (max-width: 760px) {
    .business-review {
      margin-block: 0;
      margin-inline: 0;
      padding: 20px;
      box-shadow: none;
      border-radius: 0;
      grid-template-columns: 1fr;

      .label, .value {
        font-size: 1rem;
      }
    }
  }

  // @media only screen and (max-width: 600px) {
  //   .business-review {
  //     grid-template-columns: 1fr;
  //   }
  // }
}

#review-checkout {
  .review-body {
    margin: 5%;
    margin-inline: 15%;
    background-color: white;
    color: black;
    padding: 3%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 30px;
  }

  .review-grid {
    display: grid;
    grid-template-columns: 1fr;
  }

  .mini-grid {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;
    padding-block: 5px;
  }

  .white-line {
    border-bottom: 1px solid black;
    margin-bottom: 0;
  }

  .white-line2 {
    border-bottom: 2px solid black;
    margin-bottom: 0;
  }

  .white-line3 {
    border-bottom: 3px solid black;
    margin-bottom: 0;
  }

  .bold {
    font-weight: 900;
  }

  .red-text {
    color: #F59191;
    font-weight: 900;
  }

  .grey-text {
    color: rgb(61, 61, 61);
    font-weight: 100;
    font-size: small;
  }

  .small-text {
    font-size: small !important;
    font-weight: 100;
  }

  .medium-text {
    font-size: medium;
  }

  .large-text {
    font-size: large;
  }

  .x-large-text {
    font-size: x-large;
    font-weight: 900;
  }

  .xx-large-text {
    font-size: xx-large;
    font-weight: 900;
  }

  .xxx-large-text {
    font-size: xxx-large;
    font-weight: 900;
  }

  .text-align-left {
    text-align: left;
  }

  .text-align-right {
    text-align: right;
  }

  .text-align-center {
    text-align: center;
  }

  @media only screen and (max-width: 760px) {
    .review-body {
      margin: 0;
      margin-inline: 0;
      padding: 20px;
      box-shadow: none;
      border-radius: 0;
    }
  }


  @media only screen and (max-width: 600px) {
    .small-text {
      font-size: x-small !important;
    }

    .medium-text {
      font-size: small;
    }

    .mini-grid {
      grid-template-columns: 2.5fr 2.5fr 2.5fr;
    }

    .grey-text {
      color: rgb(79, 79, 79);
      font-size: x-small;
      font-weight: 300;
    }

    .large-text {
      font-size: medium;
    }

    .x-large-text {
      font-size: large;
      font-weight: 900;
    }

    .xx-large-text {
      font-size: 1.4rem;
      font-weight: 900;
    }

  }
}
</style>
