<template>
  <div class="adminList">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <card no-body body-classes="px-0 pb-1" footer-classes="pb-2">
        <div slot="header" class="row align-items-center">
          <div class="col-lg-9 col-8">
            <h3 class="mb-0">Analytics</h3>
          </div>
          <div class="col-lg-3 pull-left"></div>
        </div>

        <b-row>
          <div class="col-lg-12 col-md-12 col-12">
            <b-card no-body>
              <b-card-body v-if="inactiveModule">
                <b-row>
                  <div class="col-lg-12 col-md-12 col-12">
                    <p class="heading text-center">
                      Coming Soon. Under Development
                    </p>
                  </div>
                </b-row>
              </b-card-body>
              <b-card-body v-else>
                <b-row>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="col-lg-3 pull-right no-gutters">
                      <base-input
                        v-model="searchRequest"
                        prepend-icon="fas fa-search"
                        placeholder="Search from all details"
                      ></base-input>
                    </div>
                  </div>
                </b-row>
                <b-row v-if="loading">
                  <div class="col-lg-12 text-center">
                    <pulse-loader color="var(--pulse-loader)"></pulse-loader>
                  </div>
                </b-row>

                <b-table
                  v-if="!loading"
                  class="waveFilesTable"
                  id="waveFilesTable"
                  responsive
                  hover
                  stacked="md"
                  :items="waveFilesList"
                  :fields="ListFields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="searchRequest"
                  :filterIncludedFields="filterFields"
                  :currentPage="currentPage"
                  :per-page="perPage"
                  head-variant="light"
                  @filtered="onSearch"
                >
                  <div slot="shortDescription" slot-scope="item" class="data">
                    <span class="title text-break-all">{{
                      item.item.shortDescription
                    }}</span>
                  </div>
                  <div slot="file" slot-scope="item" class="data">
                    <span class="title text-break-all"
                      ><a
                        ><i
                          class="far fa-file-html description"
                          v-b-tooltip.hover
                          title="Check file"
                          @click="$bvModal.show(`file${item.item.waveFileID}`)"
                        ></i></a
                    ></span>
                    <b-modal
                      :id="`file${item.item.waveFileID}`"
                      :title="`${capitalizeWords(
                        getFileType(item.item.filename)
                      )} File`"
                      centered
                      hide-footer
                      :header-text-variant="'gray'"
                    >
                      <div class="d-block text-center groupInfo">
                        <div
                          class="w-100"
                          v-if="
                            getFileType(item.item.filename) === 'png' ||
                            getFileType(item.item.filename) === 'jpg' ||
                            getFileType(item.item.filename) === 'jpeg'
                          "
                        >
                          <img :src="item.item.url" class="w-50" />
                        </div>
                        <div id="html" class="player-wrapper" v-else>
                          <html-player
                            :file="item.item.url"
                            :hasVolume="false"
                          ></html-player>
                        </div>
                      </div>
                    </b-modal>
                  </div>
                  <div slot="dateCreated" slot-scope="item" class="data">
                    <span class="title text-break-all">{{
                      item.item.dateCreated
                    }}</span>
                  </div>

                  <div slot="actions" slot-scope="item" class="data">
                    <div v-if="item.item.actions !== currentUserID">
                      <b-button
                        size="sm"
                        @click="item.toggleDetails"
                        v-b-tooltip.hover
                        :title="
                          !item.detailsShowing ? 'Show Details' : 'Hide Details'
                        "
                        class="btn btn-outline-vobo btn-sm"
                      >
                        <span class="btn-inner--icon"
                          ><i
                            :class="{
                              'far fa-eye-slash': item.detailsShowing,
                              'far fa-eye': !item.detailsShowing,
                            }"
                          ></i>
                        </span>
                      </b-button>
                      <router-link
                        :to="`/html-manager/edit/${item.item.actions}`"
                        v-b-tooltip.hover
                        title="Edit Html File"
                        class="btn btn-outline-primary btn-sm"
                      >
                        <span class="btn-inner--icon"
                          ><i class="far fa-edit"></i>
                        </span>
                      </router-link>
                      <a
                        @click="
                          deleteProcess(
                            item.item.actions,
                            item.item.waveFilesName
                          )
                        "
                        v-b-tooltip.hover
                        title="Delete Html File"
                        class="btn btn-outline-danger btn-sm text-danger"
                      >
                        <span class="btn-inner--icon"
                          ><i class="far fa-trash-alt"></i>
                        </span>
                      </a>
                    </div>
                  </div>
                  <template slot="row-details" slot-scope="item">
                    <b-card class="userInfoRow p-3 mb-0">
                      <a
                        class="mr-3 pull-right"
                        @click="item.toggleDetails"
                        v-b-tooltip.hover
                        title="Close"
                        ><i class="fas fa-times text-muted pull-right"></i
                      ></a>
                      <b-row class="mb-2 mt-3">
                        <b-col lg="6" md="6" sm="12">
                          <b-row class="mb-2">
                            <b-col lg="4" md="4" sm="4" class="text-sm-right"
                              ><b>Description:</b></b-col
                            >
                            <b-col lg="8" md="8" sm="8">{{
                              item.item.longDescription
                            }}</b-col>
                          </b-row>
                        </b-col>
                        <b-col lg="6" md="6" sm="12">
                          <b-row class="mb-2">
                            <b-col lg="3" md="3" sm="3" class="text-sm-right"
                              ><b>Filename:</b></b-col
                            >
                            <b-col lg="9" md="9" sm="9">{{
                              item.item.filename
                            }}</b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-card>
                  </template>
                </b-table>
                <b-row v-if="waveFilesList.length === 0 && !loading">
                  <b-col sm="12">
                    <h4
                      class="card-heading text-center"
                      v-if="selectedCompany === undefined"
                    >
                      Please Select Organization First
                    </h4>
                    <h4 class="card-heading text-center" v-else>
                      No records found
                    </h4>
                  </b-col>
                </b-row>
                <b-pagination
                  v-if="totalRows > 5"
                  :total-rows="totalRows"
                  @change="updateCurrentPage"
                  :per-page="perPage"
                  v-model="currentPage"
                  class="my-0"
                  align="center"
                />
                <div
                  class="float-right"
                  v-if="totalRows > 5 && waveFilesList.length !== 0 && !loading"
                >
                  <div class="float-right d-flex align-items-center">
                    <div class="mr-3 text-nowrap">Per Page</div>
                    <b-form-select :options="pageOptions" v-model="perPage" />
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </div>
        </b-row>
      </card>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import Cookies from "js-cookie";
import moment from "moment";
import showConfirmation from "@/utils/mixins/deleteNotify";
import config from "config";
import { decoder } from "@/utils/sessions/jwt";

Vue.use(Cookies);

export default {
  name: "AdminList",
  mixins: [showConfirmation],
  data: function () {
    return {
      inactiveModule: true,
      errorFields: [],
      searchRequest: "",
      currentPage: 1,
      totalRows: 0,
      totalFiles: 0,
      perPage: 5,
      pageOptions: [5, 10, 20, 50],
      selectedFilter: "all",
      selectedStatus: "all",
      filterFields: ["shortDescription"],
      statuses: [
        { value: "all", text: "All" },
        { value: "active", text: "Active" },
        { value: "inactive", text: "Inactive" },
      ],
      sortBy: "dateCreated",
      sortDesc: true,
      waveFilesListFilter: [],
      ListFields: {
        shortDescription: {
          key: "shortDescription",
          label: "Description",
          sortable: true,
        },
        file: {
          key: "file",
          label: "File",
          sortable: true,
        },
        dateCreated: {
          key: "dateCreated",
          label: "Date Created",
          sortable: true,
        },
        actions: {
          label: "actions",
        },
      },
      updateAccess: false,
    };
  },
  computed: {
    loading() {
      return this.$store.state.waveManagement.loading;
    },
    waveFilesList: {
      get() {
        let filter = "all";
        return this.waveFilesListFilter;
      },
      set(newList) {
        return newList;
      },
    },
    currentUserID: {
      get: function () {
        return this.$store.state.user.userID;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    selectedCompany: {
      get: function () {
        let selectedCompany =
          this.$store.state.company.selectedCompany || Cookies.get("companyID");
        return selectedCompany;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    currentUserGroup: {
      get: function () {
        return this.$store.state.user.userGroup;
      },
      set: function (newValue) {
        return newValue;
      },
    },
  },
  methods: {
    openHtmlFileForm() {
      let companyID = Cookies.get("companyID") || "";
      if (!companyID || companyID === "") {
        this.$swal.fire({
          type: "warning",
          position: "center",
          imageWidth: 150,
          title: "Organization is Required",
          html: "Please select organization from the dropdown first located in upper left of the screen in order to upload html file",
          showConfirmButton: true,
          showCancelButton: false,
          confirmButtonText: "Ok",
          confirmButtonClass: "btn btn-primary pr-5 pl-5",
          footer: '<div class="text-center"> </div>',
          buttonsStyling: false,
          allowOutsideClick: false,
        });
      } else {
        this.$router.push("/html-manager/create");
      }
    },
    getFileType(filename) {
      var type = filename.split(".");
      return type[1] || "wav";
    },
    capitalizeWords(value) {
      return value.toUpperCase();
    },
    loadHtmlFiles(currentUserGroup, companyID, currentPage = 1) {
      if (companyID) {
        if (currentUserGroup === 4 || currentUserGroup === 2) {
          this.$store
            .dispatch("waveManagement/GET_WAVE_FILE_BY_CP_ID")
            .then((resp) => {
              this.waveFilesListFilter =
                this.$store.state.waveManagement.waveList;
              // this.totalRows = this.totalFiles = this.waveFilesListFilter.length
              this.currentPage = currentPage;
            });
        } else {
          this.$store
            .dispatch("waveManagement/GET_WAVE_FILES", companyID)
            .then((resp) => {
              this.waveFilesListFilter =
                this.$store.state.waveManagement.waveList;
              this.totalRows = this.waveFilesListFilter.length;
              if (companyID === undefined) {
                // this.ListFields.companyID = {key: 'companyID', label: 'Company ID', sortable: true}
                /*this.listField = {
                  fullname: { key: 'category', label: 'UnionTypes', sortable: true },
                  image: { key: 'image', label: 'image' },
                  companyID: { key: 'companyID', label: 'Company ID', sortable: true },
                  date: { key: 'dateCreated', label: 'date created', sortable: true },
                  actions: { label: 'actions' }
                }*/
              }
            });
        }
      }
    },
    onSearch(filteredItems) {
      this.$nextTick(() => {
        this.errorFields = [];
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      });
    },
    updateCurrentPage(currentPage) {
      this.errorFields = [];
      this.currentPage = currentPage;
    },
    loadInitialPendingVisibleItems() {
      this.$nextTick(() => {
        // this.getPendingVisibleItems()
      });
    },
    deleteProcess(id, item) {
      const waveFilesID = String(id).trim();
      const itemString = `this wave file`;
      this.showConfirmation(this.deletePackage, waveFilesID, itemString);
    },
    deletePackage(waveFilesID) {
      this.$store
        .dispatch("waveManagement/DELETE_WAVE_FILE", waveFilesID)
        .then((res) => {
          if (res.data.success) {
            this.$notify({
              message: this.$options.filters.capitalizeWords(
                "Package user successfully deleted"
              ),
              timeout: 4000,
              icon: "fas fa-check",
              type: "success",
            });
            this.loadHtmlFiles(
              this.currentUserGroup,
              this.selectedCompany,
              this.currentPage
            );
          } else {
            this.$notify({
              message: this.$options.filters.capitalizeWords(
                "Error deleting waveFiles"
              ),
              timeout: 4000,
              icon: "fas fa-exclamation-triangle",
              type: "danger",
            });
          }
        })
        .catch((err) => {
          console.error(err);
          err.then((error) => {
            this.$notify({
              message: this.$options.filters.capitalizeWords(error.errMessage),
              timeout: 4000,
              icon: "fas fa-exclamation-triangle",
              type: "danger",
            });
            this.hasError = true;
          });
        });
    },
  },
  watch: {
    selectedStatus: function (context) {
      this.waveFilesListFilter = this.$store.state.waveFiles.cpList[context];
      this.totalRows = this.waveFilesListFilter.length;
    },
  },
  mounted() {
    let token = Cookies.get("user-token");

    const decode = decoder({
      secret: config.secret,
    });

    var payload = decode(token);
  },
};
</script>
<style lang="scss">
.table .thead-light .hdcolor {
  color: #536c79 !important;
}
.transaction-error {
  font-size: 0.68rem;
}
i.description {
  font-size: 1.5rem !important;
}
a.btn-outline-danger:hover {
  color: #fff !important;
}
a.btn-outline-vobo:hover {
  color: #fff !important;
}
a.btn-outline-default:hover {
  color: #fff !important;
}
a.btn-outline-primary:hover {
  color: #fff !important;
}
.waveFilesTable td a {
  margin-right: 0.25rem !important;
}
.waveFilesTable td a:last-child {
  margin-right: 0 !important;
}
.waveFilesTable .table td {
  padding: 0.7rem;
}
.waveFilesTable tr {
  th {
    text-align: center;
  }
  &:focus {
    outline: 0 !important;
    outline: none !important;
  }
}
.waveFilesTable tr th:first-child,
.waveFilesTable tr td:first-child {
  width: 30% !important;
}
.waveFilesTable tr th:nth-child(2),
.waveFilesTable tr td:nth-child(2) {
  width: 20% !important;
  text-align: center;
}
.waveFilesTable tr th:nth-child(3),
.waveFilesTable tr td:nth-child(3) {
  width: 30% !important;
  text-align: center;
}
.waveFilesTable tr th:nth-child(3),
.waveFilesTable tr td:nth-child(3) {
  width: 20% !important;
  text-align: center;
}

.waveFilesTable tr th:nth-child(4),
.waveFilesTable tr td:nth-child(4) {
  width: 25% !important;
  text-align: center;
}
</style>
