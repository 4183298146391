<template>
  <div class="profile">
    <notifications></notifications>
    <base-header class="custom-base-header custom-header-bg" :class="{'pb-6': !registrationSuccess, 'pb-1': registrationSuccess}">
      <div class="row align-items-center py-4 custom-header">
        <img src="./logo.png" alt="" />
        <!-- <div class="col-lg-4 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div> -->
      </div>
    </base-header>
    <div class="container mt--6 minHeight form-container" v-if="!registrationSuccess">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card
            no-body
            body-classes="px-0 pb-1"
            footer-classes="pb-2"
            class="createClientContainer"
          >
            <b-row id="processAdminForm">
              <div class="col-lg-12 col-md-12 col-12 remove-padding">
                <b-card no-body>
                  <b-card-body class="p-0 m-0">
                    <b-row>
                      <form class="w-100">
                        <div id="business_information">
                          <div class="form-head">
                            <h1>1. Business Information</h1>
                          </div>
                          <div class="form-body">
                            <div class="row">
                              <div class="col-lg-6">
                                <b-form-group
                                  role="group"
                                  class="mb-3 has-feedback"
                                >
                                  <label
                                    class="w-100 form-control-label"
                                    for="firstname"
                                    >Business Name
                                  </label>
                                  <input
                                    type="text"
                                    name="name"
                                    v-model.trim="business.name"
                                    data-vv-as="Business Name"
                                    v-validate="'required'"
                                    data-vv-name="name"
                                    placeholder="Business Name"
                                    autocomplete="section-business organization"
                                    class="form-control no-instruction"
                                    :class="{
                                      input: true,
                                      'is-invalid': errors.has('name'),
                                      'has-invalid': errors.has('name'),
                                    }"
                                  />
                                  <span class="has-icon-feedback"></span>
                                  <div
                                    class="invalid-feedback"
                                    :is-invalid="errors.has('name')"
                                  >
                                    {{ errors.first("name") }}
                                  </div>
                                </b-form-group>

                                <b-form-group
                                  role="group"
                                  class="mb-3 has-feedback"
                                >
                                  <label
                                    class="w-100 form-control-label"
                                    for="lastname"
                                    >Business Address</label
                                  >
                                  <input
                                    type="text"
                                    name="line1"
                                    v-model.trim="
                                      business.location.address.line1
                                    "
                                    data-vv-as="Address Line 1"
                                    data-vv-name="line1"
                                    placeholder="Address Line 1"
                                    autocomplete="section-business address-line1"
                                    class="form-control no-instruction"
                                    :class="{
                                      input: true,
                                      'is-invalid': errors.has('line1'),
                                      'has-invalid': errors.has('line1'),
                                    }"
                                  />
                                  <span class="has-icon-feedback"></span>
                                  <div
                                    class="invalid-feedback"
                                    :is-invalid="errors.has('line1')"
                                  >
                                    {{ errors.first("line1") }}
                                  </div>
                                </b-form-group>

                                <b-form-group
                                  role="group"
                                  class="mb-3 has-feedback"
                                >
                                  <input
                                    type="text"
                                    name="line2"
                                    v-model.trim="
                                      business.location.address.line2
                                    "
                                    data-vv-as="Address Line 2"
                                    data-vv-name="line2"
                                    placeholder="Address Line 2"
                                    autocomplete="section-business address-line2"
                                    class="form-control no-instruction"
                                    :class="{
                                      input: true,
                                      'is-invalid': errors.has('line2'),
                                      'has-invalid': errors.has('line2'),
                                    }"
                                  />
                                  <span class="has-icon-feedback"></span>
                                  <div
                                    class="invalid-feedback"
                                    :is-invalid="errors.has('line2')"
                                  >
                                    {{ errors.first("line2") }}
                                  </div>
                                </b-form-group>

                                <b-form-group
                                  role="group"
                                  class="mb-3 has-feedback"
                                >
                                  <label
                                    class="w-100 form-control-label"
                                    for="businessEmail"
                                    >Business Email (Optional)
                                  </label>
                                  <input
                                    type="text"
                                    name="businessEmail"
                                    v-model.trim="business.email"
                                    v-validate="'email'"
                                    data-vv-name="businessEmail"
                                    data-vv-as="Business Email"
                                    placeholder="Enter Business Email Address"
                                    autocomplete="section-business email"
                                    class="form-control no-instruction"
                                    :class="{
                                      input: true,
                                      'is-invalid': errors.has('businessEmail'),
                                      'has-invalid':
                                        errors.has('businessEmail'),
                                    }"
                                  />
                                  <span class="has-icon-feedback"></span>
                                  <div
                                    class="invalid-feedback"
                                    :is-invalid="errors.has('businessEmail')"
                                  >
                                    {{ errors.first("businessEmail") }}
                                  </div>
                                </b-form-group>

                                <b-form-group
                                  role="group"
                                  class="mb-3 has-feedback"
                                >
                                  <label
                                    class="w-100 form-control-label"
                                    for="phone"
                                    >Business Phone Number (Optional)
                                  </label>
                                  <input
                                    type="text"
                                    name="businessContact"
                                    v-model.trim="business.phone"
                                    v-mask="'### ###-####'"
                                    v-validate="{
                                      min: 10,
                                    }"
                                    data-vv-name="businessContact"
                                    data-vv-as="Business Phone Number"
                                    placeholder="Enter Business Phone Number (123 123-1234)"
                                    autocomplete="section-business tel"
                                    class="form-control no-instruction"
                                    :class="{
                                      input: true,
                                      'is-invalid':
                                        errors.has('businessContact'),
                                      'has-invalid':
                                        errors.has('businessContact'),
                                    }"
                                  />
                                  <span class="has-icon-feedback"></span>
                                  <div
                                    class="invalid-feedback"
                                    :is-invalid="errors.has('businessContact')"
                                  >
                                    {{ errors.first("businessContact") }}
                                  </div>
                                </b-form-group>
                              </div>
                              <div class="col-lg-6">
                                <b-form-group
                                  role="group"
                                  class="mb-3 has-feedback"
                                >
                                  <label
                                    class="w-100 form-control-label"
                                    for="short"
                                    >Short Description</label
                                  >
                                  <input
                                    type="text"
                                    name="short"
                                    v-model.trim="business.description.short"
                                    data-vv-as="Short Description"
                                    v-validate="'required'"
                                    data-vv-name="short"
                                    placeholder="Business Short Description"
                                    class="form-control no-instruction"
                                    :class="{
                                      input: true,
                                      'is-invalid': errors.has('short'),
                                      'has-invalid': errors.has('short'),
                                    }"
                                  />
                                  <span class="has-icon-feedback"></span>
                                  <div
                                    class="invalid-feedback"
                                    :is-invalid="errors.has('short')"
                                  >
                                    {{ errors.first("short") }}
                                  </div>
                                </b-form-group>

                                <b-form-group
                                  role="group"
                                  class="mb-3 has-feedback"
                                >
                                  <label
                                    class="w-100 form-control-label"
                                    for="long"
                                    >Long Description
                                  </label>

                                  <textarea
                                    id="long"
                                    name="long"
                                    v-model.trim="business.description.long"
                                    v-validate="'required'"
                                    data-vv-name="long"
                                    data-vv-as="Long Description"
                                    class="form-control no-instruction long"
                                    placeholder="Enter Long Description"
                                    rows="4"
                                    :class="{
                                      input: true,
                                      'is-invalid': errors.has('long'),
                                      'has-invalid': errors.has('long'),
                                    }"
                                  >
                                  </textarea>
                                  <span class="has-icon-feedback"></span>
                                  <div
                                    class="invalid-feedback"
                                    :is-invalid="errors.has('long')"
                                  >
                                    {{ errors.first("long") }}
                                  </div>
                                </b-form-group>

                                <b-form-group
                                  role="group"
                                  class="mb-3 has-feedback"
                                >
                                  <label
                                    class="w-100 form-control-label"
                                    for="website"
                                    >Website (Optional)</label
                                  >
                                  <input
                                    type="text"
                                    name="website"
                                    v-model.trim="business.website"
                                    data-vv-as="Business Website"
                                    data-vv-name="website"
                                    placeholder="Business Website"
                                    autocomplete="section-business url"
                                    class="form-control no-instruction"
                                    :class="{
                                      input: true,
                                      'is-invalid': errors.has('website'),
                                      'has-invalid': errors.has('website'),
                                    }"
                                  />
                                  <span class="has-icon-feedback"></span>
                                  <div
                                    class="invalid-feedback"
                                    :is-invalid="errors.has('website')"
                                  >
                                    {{ errors.first("website") }}
                                  </div>
                                </b-form-group>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div id="user_account">
                          <div class="form-head">
                            <h1>2. User Account</h1>
                          </div>
                          <div class="form-body">
                            <div class="row">
                              <div class="col-lg-6">
                                <b-form-group
                                  role="group"
                                  class="mb-3 has-feedback"
                                >
                                  <label
                                    class="w-100 form-control-label"
                                    for="firstname"
                                    >First Name
                                  </label>
                                  <input
                                    type="text"
                                    name="firstname"
                                    v-model.trim="firstname"
                                    data-vv-as="First Name"
                                    v-validate="'required'"
                                    data-vv-name="firstname"
                                    placeholder="First Name"
                                    autocomplete="section-user given-name"
                                    class="form-control no-instruction"
                                    :class="{
                                      input: true,
                                      'is-invalid': errors.has('firstname'),
                                      'has-invalid': errors.has('firstname'),
                                    }"
                                  />
                                  <span class="has-icon-feedback"></span>
                                  <div
                                    class="invalid-feedback"
                                    :is-invalid="errors.has('firstname')"
                                  >
                                    {{ errors.first("firstname") }}
                                  </div>
                                </b-form-group>

                                <b-form-group
                                  role="group"
                                  class="mb-3 has-feedback"
                                >
                                  <label
                                    class="w-100 form-control-label"
                                    for="lastname"
                                    >Last Name
                                  </label>
                                  <input
                                    type="text"
                                    name="lastname"
                                    v-model.trim="lastname"
                                    data-vv-as="Last Name"
                                    v-validate="'required'"
                                    data-vv-name="lastname"
                                    placeholder="Last Name"
                                    autocomplete="section-user family-name"
                                    class="form-control no-instruction"
                                    :class="{
                                      input: true,
                                      'is-invalid': errors.has('lastname'),
                                      'has-invalid': errors.has('lastname'),
                                    }"
                                  />
                                  <span class="has-icon-feedback"></span>
                                  <div
                                    class="invalid-feedback"
                                    :is-invalid="errors.has('lastname')"
                                  >
                                    {{ errors.first("lastname") }}
                                  </div>
                                </b-form-group>
                              </div>
                              <div class="col-lg-6">
                                <b-form-group
                                  role="group"
                                  class="mb-3 has-feedback"
                                >
                                  <label
                                    class="w-100 form-control-label"
                                    for="phoneNumber"
                                    >Phone Number
                                  </label>
                                  <input
                                    type="text"
                                    name="phoneNumber"
                                    v-model.trim="phone"
                                    v-mask="'### ###-####'"
                                    v-validate="{
                                      required: true,
                                      min: 10,
                                    }"
                                    data-vv-name="phoneNumber"
                                    data-vv-as="Phone Number"
                                    placeholder="Enter Phone Number (123 123-1234)"
                                    autocomplete="section-user tel"
                                    class="form-control no-instruction"
                                    :class="{
                                      input: true,
                                      'is-invalid': errors.has('phoneNumber'),
                                      'has-invalid': errors.has('phoneNumber'),
                                    }"
                                  />
                                  <span class="has-icon-feedback"></span>
                                  <div
                                    class="invalid-feedback"
                                    :is-invalid="errors.has('phoneNumber')"
                                  >
                                    {{ errors.first("phoneNumber") }}
                                  </div>
                                </b-form-group>

                                <b-form-group
                                  role="group"
                                  class="mb-3 has-feedback"
                                >
                                  <label
                                    class="w-100 form-control-label"
                                    for="userEmail"
                                    >Email
                                  </label>
                                  <input
                                    type="text"
                                    name="userEmail"
                                    v-model.trim="email"
                                    v-validate="'required|email'"
                                    data-vv-name="userEmail"
                                    data-vv-as="User Email"
                                    placeholder="Enter Email Address"
                                    autocomplete="section-user email"
                                    class="form-control no-instruction"
                                    :class="{
                                      input: true,
                                      'is-invalid':
                                        errors.has('userEmail') || emailIsExist,
                                      'has-invalid':
                                        errors.has('userEmail') || emailIsExist,
                                    }"
                                  />
                                  <span class="has-icon-feedback"></span>
                                  <div
                                    class="invalid-feedback"
                                    :is-invalid="errors.has('userEmail')"
                                  >
                                    {{ errors.first("userEmail") }}
                                  </div>
                                  <div
                                    v-if="emailIsExist"
                                    class="invalid-feedback"
                                  >
                                    Email is already exist
                                  </div>
                                </b-form-group>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-lg-6">
                                <b-form-group
                                  role="group"
                                  class="mb-3 has-feedback"
                                >
                                  <label
                                    class="w-100 form-control-label"
                                    for="password"
                                  >
                                    Password
                                    <small
                                      class="pull-right mr-1 show-pass-icon"
                                      v-if="passwordShow"
                                      ><a
                                        ><i
                                          @click="switchVisibility('password')"
                                          class="text-gray"
                                          :class="iconType.password"
                                        ></i></a
                                    ></small>
                                  </label>
                                  <input
                                    :type="passwordFieldType.password"
                                    name="password"
                                    v-model.trim="password"
                                    @input="passwordCheck"
                                    data-vv-as="Password"
                                    v-validate="{
                                      required: action === 'create',
                                    }"
                                    data-vv-name="password"
                                    data-vv-delay="1000"
                                    placeholder="Enter new password"
                                    autocomplete="section-user new-password"
                                    class="form-control no-instruction"
                                    :class="{
                                      input: true,
                                      'is-invalid': errors.has('password'),
                                      'has-invalid':
                                        errors.has('password') ||
                                        (password.length > 0 &&
                                          progressWidth <= 40),
                                      'has-valid':
                                        progressWidth >= 75 &&
                                        hasLowercaseUppercase &&
                                        hasNumber &&
                                        hasSpecialChar,
                                      'has-warning':
                                        password.length > 0 &&
                                        progressWidth > 40 &&
                                        progressWidth < 75,
                                    }"
                                  />
                                  <span class="has-icon-feedback"></span>
                                  <div
                                    class="invalid-feedback"
                                    :is-invalid="errors.has('password')"
                                  >
                                    {{ errors.first("password") }}
                                  </div>
                                </b-form-group>

                                <b-col
                                  md="6"
                                  class="
                                    order-md-last
                                    text-default
                                    strong
                                    password-validator-text
                                  "
                                >
                                  <small class="font-weight-bold"
                                    >User password must not contain any blank
                                    spaces and meet the following
                                    criteria:</small
                                  >
                                  <ul class="list-icon pt-3">
                                    <li
                                      :class="{
                                        'icon-check-circle-o muted':
                                          password.length == 0,
                                        'icon-check-circle valid':
                                          password.length >= 8,
                                        'icon-warning invalid':
                                          password.length < 8 &&
                                          password.length > 0,
                                      }"
                                    >
                                      8 characters
                                    </li>
                                    <li
                                      :class="{
                                        'icon-check-circle-o muted':
                                          hasLowerCase === false &&
                                          hasUpperCase === false,
                                        'icon-exclamation-circle warning':
                                          (!hasLowerCase && hasUpperCase) ||
                                          (hasUpperCase === false &&
                                            hasLowerCase),
                                        'icon-check-circle valid':
                                          hasLowercaseUppercase,
                                        'icon-warning invalid':
                                          hasLowercaseUppercase === false &&
                                          password.length > 0,
                                      }"
                                    >
                                      Uppercase and lowercase letters
                                    </li>
                                    <li
                                      :class="{
                                        'icon-check-circle-o muted':
                                          password.length == 0,
                                        'icon-check-circle valid': hasNumber,
                                        'icon-warning invalid':
                                          !hasNumber && password.length > 0,
                                      }"
                                    >
                                      Number(s)
                                    </li>
                                    <li
                                      :class="{
                                        'icon-check-circle-o muted':
                                          password.length == 0,
                                        'icon-check-circle valid':
                                          hasSpecialChar,
                                        'icon-warning invalid':
                                          !hasSpecialChar &&
                                          password.length > 0,
                                      }"
                                    >
                                      Special character(s)
                                    </li>
                                  </ul>
                                  <div class="progress">
                                    <div
                                      class="progress-bar passwordMeter"
                                      role="progressbar"
                                      v-bind:style="{
                                        width: progressWidth + '%',
                                      }"
                                      aria-valuenow="0"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    >
                                      {{ progressLabel }}
                                    </div>
                                  </div>
                                </b-col>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div id="subscription">
                          <div class="form-head">
                            <h1>3. Select Subscription</h1>
                          </div>
                          <div class="form-body">
                            <div class="subscription-container">
                              <div
                                v-for="subscription in subscriptions"
                                :key="subscription._id"
                                :class="[
                                  'subscription-card',
                                  {
                                    selectedSubscription:
                                      selectedSubscription == subscription._id,
                                  },
                                ]"
                                @click.prevent="
                                  selectSubscription(
                                    subscription._id,
                                    subscription.numberOfImages,
                                    subscription.plan_id
                                  )
                                "
                              >
                                <h3>{{ subscription.name }}</h3>
                                <p class="as-low" v-if="subscription.price != 0">As low as</p>
                                <div class="price" v-if="subscription.price == 0">FREE</div>
                                <div class="price" v-else>
                                  ${{ subscription.price.toFixed(2) }}
                                  <span v-if="subscription.term" class="term">
                                    every&nbsp;{{
                                      subscription.term.value
                                    }}&nbsp;{{ subscription.term.period
                                    }}{{
                                      subscription.term.value > 1 ? "s" : ""
                                    }}
                                  </span>
                                </div>
                                <div
                                  class="trial"
                                  v-if="
                                    subscription.trial &&
                                    subscription.trial.value > 0
                                  "
                                >
                                  <strong>1st payment</strong> after&nbsp;<strong>{{
                                    subscription.term.value
                                  }}&nbsp;{{
                                    subscription.term.period
                                  }}&nbsp;FREE</strong> trial! Cancel anytime.
                                </div>
                                <div
                                  class="trial"
                                  v-if="
                                    subscription.trial &&
                                    subscription.trial.description
                                  "
                                  v-html="subscription.trial.description"
                                ></div>
                                <button class="select-btn">
                                  {{
                                    selectedSubscription == subscription._id
                                      ? "Selected"
                                      : "Select Subscription"
                                  }}
                                </button>
                                <div
                                  class="description"
                                  v-html="subscription.description"
                                ></div>
                                <div
                                  v-if="
                                    selectedSubscription == subscription._id
                                  "
                                  class="note-selected"
                                >
                                  <img
                                    src="../../assets/images/checked.png"
                                    alt=""
                                    srcset=""
                                  />
                                </div>
                                <br />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div id="advertisement_information">
                          <div class="form-head">
                            <h1>4. Listing Information</h1>
                          </div>
                          <div class="form-body">
                            <div class="row">
                              <div class="col-lg-4">
                                <b-form-group
                                  role="group"
                                  class="mb-3 has-feedback"
                                >
                                  <label
                                    class="w-100 form-control-label"
                                    for="city"
                                    >Select City
                                  </label>
                                  <b-form-select
                                    v-model="ads.adsCity"
                                    :options="cities"
                                    name="city"
                                    placeholder="Please select city"
                                    v-validate="'required'"
                                    data-vv-as="City"
                                    data-vv-name="city"
                                    class="
                                      form-control
                                      no-instruction
                                      custom-select
                                    "
                                    :class="{
                                      'is-invalid': errors.has('city'),
                                      'has-invalid': errors.has('city'),
                                    }"
                                  >
                                  </b-form-select>
                                  <span class="has-icon-feedback"></span>
                                  <div
                                    class="invalid-feedback"
                                    :is-invalid="errors.has('city')"
                                  >
                                    {{ errors.first("city") }}
                                  </div>
                                </b-form-group>

                                <b-form-group
                                  role="group"
                                  class="mb-3 has-feedback"
                                >
                                  <label
                                    class="w-100 form-control-label"
                                    for="communityCategory"
                                    >Select Community Category
                                  </label>
                                  <b-form-select
                                    v-model="communityCategory"
                                    :options="communityCategories"
                                    name="communityCategory"
                                    placeholder="Please select community category"
                                    v-validate="'required'"
                                    data-vv-as="Community Category"
                                    data-vv-name="communityCategory"
                                    class="
                                      form-control
                                      no-instruction
                                      custom-select
                                    "
                                    :class="{
                                      'is-invalid':
                                        errors.has('communityCategory'),
                                      'has-invalid':
                                        errors.has('communityCategory'),
                                    }"
                                    @change="getSubCategories()"
                                  >
                                  </b-form-select>
                                  <span class="has-icon-feedback"></span>
                                  <div
                                    class="invalid-feedback"
                                    :is-invalid="
                                      errors.has('communityCategory')
                                    "
                                  >
                                    {{ errors.first("communityCategory") }}
                                  </div>
                                </b-form-group>

                                <b-form-group
                                  v-if="communityCategory != null"
                                  role="group"
                                  class="mb-3 has-feedback"
                                >
                                  <div v-if="subCategories.length > 1">
                                    <label
                                      class="w-100 form-control-label"
                                      for="subCategory"
                                      >Select Sub Category
                                    </label>
                                    <b-form-select
                                      v-model="ads.adsCategory"
                                      :options="subCategories"
                                      name="subCategory"
                                      placeholder="Please select subCategory"
                                      v-validate="'required'"
                                      data-vv-as="Sub Category"
                                      data-vv-name="subCategory"
                                      class="
                                        form-control
                                        no-instruction
                                        custom-select
                                      "
                                      :class="{
                                        'is-invalid': errors.has('subCategory'),
                                        'has-invalid':
                                          errors.has('subCategory'),
                                      }"
                                    >
                                    </b-form-select>
                                    <span class="has-icon-feedback"></span>
                                    <div
                                      class="invalid-feedback"
                                      :is-invalid="errors.has('subCategory')"
                                    >
                                      {{ errors.first("subCategory") }}
                                    </div>
                                  </div>
                                  <div v-else>
                                    <label
                                      class="w-100 form-control-label error-msg"
                                      for="error"
                                      >No Sub Category Found</label
                                    >
                                  </div>
                                </b-form-group>
                              </div>
                              <div class="col-lg-8">
                                <b-form-group
                                  role="group"
                                  class="mb-3 has-feedback"
                                >
                                  <label
                                    class="w-100 form-control-label"
                                    for="uploadImages"
                                    >Upload Images</label
                                  >
                                  <b-form-file
                                    accept="image/*"
                                    v-model="urlImage"
                                    data-vv-as="Images"
                                    data-vv-name="uploadImages"
                                    v-validate="'required'"
                                    @change="onFileChange"
                                    @ondrop="onFileChange"
                                    @ondragover="onFileChange"
                                    :state="!hasImageError"
                                    :multiple="allowedImages > 1"
                                    placeholder="Choose a file or drop it here..."
                                    drop-placeholder="Drop file here..."
                                    class="upload-img-field"
                                    :class="{
                                      'is-invalid': hasImageError,
                                      'has-invalid': hasImageError,
                                    }"
                                  ></b-form-file>
                                  <div
                                    v-if="hasImageError"
                                    class="image-error-msg"
                                  >
                                    {{ imageError }}
                                  </div>
                                </b-form-group>
                                <h5 class="mt-3">
                                  <i
                                    >For best display results, make sure to
                                    upload images with a 4:3 aspect ratio.
                                    Minimum recommended size is 1024x576</i
                                  >
                                </h5>
                              </div>

                              <div class="col-lg-12">
                                <div
                                  v-if="business.images.otherImage != 0"
                                  class="preview-images"
                                >
                                  <h2>Select Cover Image</h2>
                                  <div class="row preview-container">
                                    <div
                                      v-for="img in business.images.otherImage"
                                      :key="img.index"
                                      class="mb-5"
                                      :class="[
                                        {
                                          'col-lg-4':
                                            business.images.otherImage.length >
                                            1,
                                        },
                                        {
                                          'col-lg-6':
                                            business.images.otherImage.length ==
                                            1,
                                        },
                                      ]"
                                    >
                                      <cropper
                                        ref="cropper"
                                        class="cropper"
                                        :class="[
                                          {
                                            selected:
                                              img.index ==
                                              business.images.mainImage,
                                          },
                                        ]"
                                        :src="img.image"
                                        :stencil-props="{
                                          aspectRatio: limitations.aspectRatio,
                                        }"
                                      />
                                      <div class="select-container">
                                        <div
                                          v-if="
                                            img.index !=
                                            business.images.mainImage
                                          "
                                          class="btn btn-theme"
                                          @click="selectMainImage(img.index)"
                                        >
                                          Select As Cover
                                        </div>
                                      </div>
                                    </div>

                                    <!-- <div v-for="img in business.images.otherImage" :key="img.index" class="col-lg-4 mb-5">
                                            <div class="img-container">
                                              <div :class="[{ 'selected' : img.index == business.images.mainImage }]">
                                                <img width="100%" :src="img.image" alt=""   @click="selectMainImage(img.index)">
                                              </div>
                                            </div>
                                          </div> -->
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div id="card_information" v-if="billingRequired">
                          <div class="form-head">
                            <h1>5. Card Information</h1>
                          </div>
                          <div class="form-body">
                            <b-form-group
                              role="group"
                              class="mb-3 has-feedback"
                            >
                              <label
                                class="w-100 form-control-label"
                                for="website"
                                >Cardholder Name</label
                              >
                              <input
                                type="text"
                                name="cardHolder"
                                v-model.trim="billing.card.fullName"
                                data-vv-as="Cardholder Name"
                                v-validate="'required'"
                                data-vv-name="cardHolder"
                                placeholder="Cardholder Name"
                                autocomplete="section-billing billing name"
                                class="form-control no-instruction"
                                :class="{
                                  input: true,
                                  'is-invalid': errors.has('cardHolder'),
                                  'has-invalid': errors.has('cardHolder'),
                                }"
                              />
                              <span class="has-icon-feedback"></span>
                              <div
                                class="invalid-feedback"
                                :is-invalid="errors.has('cardHolder')"
                              >
                                {{ errors.first("cardHolder") }}
                              </div>
                            </b-form-group>
                            <div class="form-group">
                              <label
                                for="card-element"
                                class="form-control-label"
                                >Card Information<span class="red-text"
                                  >*</span
                                ></label
                              >
                              <div class="has-label">
                                <div
                                  id="card-element"
                                  class="form-control"
                                ></div>
                                <div v-if="hasCardError" class="card-error-msg">
                                  {{ cardError }}
                                </div>
                              </div>
                              <div
                                class="invalid-feedback"
                                style="display: block"
                                v-if="cardInput && cardInput.error"
                              >
                                {{ cardInput.error.message }}
                              </div>
                            </div>
                            <h2 class="card-note">
                              Billing Address<small class="red-text">*</small>
                            </h2>
                            <base-input
                              :group="a > 0"
                              :label="`Address Line ${a + 1}*`"
                              v-for="(v, a) in billing.address.address"
                              :key="a"
                              rules="required"
                            >
                              <input
                                type="text"
                                :name="`Address Line ${a + 1}`"
                                class="form-control"
                                placeholder="Enter Billing Address"
                                :autocomplete="`section-billing billing address-line${
                                  a + 1
                                }`"
                                v-model="billing.address.address[a]"
                                v-validate="a == 0 ? 'required' : ''"
                                :data-vv-name="`billingAddressLine${a + 1}`"
                                data-vv-as="Billing Address"
                                :class="{
                                  input: true,
                                  'is-invalid': errors.has(
                                    `billingAddressLine${a + 1}`
                                  ),
                                  'has-invalid': errors.has(
                                    `billingAddressLine${a + 1}`
                                  ),
                                }"
                              />
                              <div class="input-group-append" v-if="a > 0">
                                <button
                                  class="btn btn-outline-danger"
                                  type="button"
                                  :disabled="
                                    billing.address.address.length === 1
                                  "
                                  @click="billing.address.address.splice(a, 1)"
                                >
                                  <i class="fa fa-times"></i>
                                </button>
                              </div>
                              <div
                                class="invalid-feedback"
                                :is-invalid="
                                  errors.has(`billingAddressLine${a + 1}`)
                                "
                              >
                                {{ errors.first(`billingAddressLine${a + 1}`) }}
                              </div>
                            </base-input>
                            <a
                              href="#"
                              class="small"
                              style="float: left; margin-top: -22px"
                              @click.prevent="
                                billing.address.address.push(null)
                              "
                              >+ Add Address Line
                              {{ billing.address.address.length + 1 }}</a
                            >
                            <div class="row">
                              <div class="col-lg-4" style="padding-left: 0">
                                <b-form-group
                                  role="group"
                                  class="mb-3 has-feedback"
                                >
                                  <label
                                    class="w-100 form-control-label"
                                    for="billingCity"
                                    >City</label
                                  >
                                  <input
                                    type="text"
                                    name="billingCity"
                                    v-model.trim="billing.address.city"
                                    data-vv-as="City"
                                    v-validate="'required'"
                                    data-vv-name="billingCity"
                                    placeholder="Enter Your City"
                                    autocomplete="section-billing billing address-level2"
                                    class="form-control no-instruction"
                                    :class="{
                                      input: true,
                                      'is-invalid': errors.has('billingCity'),
                                      'has-invalid': errors.has('billingCity'),
                                    }"
                                  />
                                  <span class="has-icon-feedback"></span>
                                  <div
                                    class="invalid-feedback"
                                    :is-invalid="errors.has('billingCity')"
                                  >
                                    {{ errors.first("billingCity") }}
                                  </div>
                                </b-form-group>
                              </div>
                              <div class="col-lg-4" style="padding-left: 0">
                                <base-input label="State*" rules="required">
                                  <select
                                    name="state"
                                    v-model="billing.address.state"
                                    data-vv-as="State"
                                    v-validate="'required'"
                                    data-vv-name="state"
                                    placeholder="Enter Your state"
                                    autocomplete="section-billing billing address-level1"
                                    class="form-control no-instruction"
                                    :class="{
                                      select: true,
                                      'is-invalid': errors.has('state'),
                                      'has-invalid': errors.has('state'),
                                    }"
                                  >
                                    <option selected disabled>
                                      SELECT FROM BELOW
                                    </option>
                                    <option value="AL">Alabama</option>
                                    <option value="AK">Alaska</option>
                                    <option value="AZ">Arizona</option>
                                    <option value="AR">Arkansas</option>
                                    <option value="CA">California</option>
                                    <option value="CO">Colorado</option>
                                    <option value="CT">Connecticut</option>
                                    <option value="DE">Delaware</option>
                                    <option value="DC">
                                      District Of Columbia
                                    </option>
                                    <option value="FL">Florida</option>
                                    <option value="GA">Georgia</option>
                                    <option value="HI">Hawaii</option>
                                    <option value="ID">Idaho</option>
                                    <option value="IL">Illinois</option>
                                    <option value="IN">Indiana</option>
                                    <option value="IA">Iowa</option>
                                    <option value="KS">Kansas</option>
                                    <option value="KY">Kentucky</option>
                                    <option value="LA">Louisiana</option>
                                    <option value="ME">Maine</option>
                                    <option value="MD">Maryland</option>
                                    <option value="MA">Massachusetts</option>
                                    <option value="MI">Michigan</option>
                                    <option value="MN">Minnesota</option>
                                    <option value="MS">Mississippi</option>
                                    <option value="MO">Missouri</option>
                                    <option value="MT">Montana</option>
                                    <option value="NE">Nebraska</option>
                                    <option value="NV">Nevada</option>
                                    <option value="NH">New Hampshire</option>
                                    <option value="NJ">New Jersey</option>
                                    <option value="NM">New Mexico</option>
                                    <option value="NY">New York</option>
                                    <option value="NC">North Carolina</option>
                                    <option value="ND">North Dakota</option>
                                    <option value="OH">Ohio</option>
                                    <option value="OK">Oklahoma</option>
                                    <option value="OR">Oregon</option>
                                    <option value="PA">Pennsylvania</option>
                                    <option value="RI">Rhode Island</option>
                                    <option value="SC">South Carolina</option>
                                    <option value="SD">South Dakota</option>
                                    <option value="TN">Tennessee</option>
                                    <option value="TX">Texas</option>
                                    <option value="UT">Utah</option>
                                    <option value="VT">Vermont</option>
                                    <option value="VA">Virginia</option>
                                    <option value="WA">Washington</option>
                                    <option value="WV">West Virginia</option>
                                    <option value="WI">Wisconsin</option>
                                    <option value="WY">Wyoming</option>
                                  </select>
                                  <span
                                    class="has-icon-feedback state-err-icon"
                                  ></span>
                                  <div
                                    class="invalid-feedback"
                                    :is-invalid="errors.has('state')"
                                  >
                                    {{ errors.first("state") }}
                                  </div>
                                </base-input>
                              </div>
                              <div class="col-lg-4" style="padding-right: 0">
                                <b-form-group
                                  role="group"
                                  class="mb-3 has-feedback"
                                >
                                  <label
                                    class="w-100 form-control-label"
                                    for="website"
                                    >Zipcode</label
                                  >
                                  <input
                                    type="text"
                                    name="Zipcode"
                                    v-model.trim="billing.address.zipCode"
                                    data-vv-as="Zipcode"
                                    v-validate="'required'"
                                    data-vv-name="Zipcode"
                                    placeholder="Enter Your Zipcode"
                                    autocomplete="section-billing billing postal-code"
                                    class="form-control no-instruction"
                                    :class="{
                                      input: true,
                                      'is-invalid': errors.has('Zipcode'),
                                      'has-invalid': errors.has('Zipcode'),
                                    }"
                                  />
                                  <span class="has-icon-feedback"></span>
                                  <div
                                    class="invalid-feedback"
                                    :is-invalid="errors.has('Zipcode')"
                                  >
                                    {{ errors.first("Zipcode") }}
                                  </div>
                                </b-form-group>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div id="submit">
                          <div class="form-body">
                            <div class="mt-5">
                              <b-row v-if="loading">
                                <div class="col-lg-12 text-center">
                                  <pulse-loader color="var(--pulse-loader)"></pulse-loader>
                                  Completing Registration... Please Wait
                                  <br />
                                  <br />
                                </div>
                              </b-row>
                              <button
                                type="button"
                                :disabled="loading"
                                id="processNewUser"
                                @click="addNewBusiness()"
                                class="btn btn-primary text-center"
                              >
                                <b-icon
                                  icon="arrow-clockwise"
                                  animation="spin"
                                  class="btn-loader hidden"
                                  font-scale="1"
                                ></b-icon>
                                Register
                                <span class="promise-btn__spinner-wrapper hidden"
                                  ><span
                                    data-v-cc3e8e04=""
                                    class="spinner"
                                    style="
                                      border-top-color: rgb(52, 152, 219);
                                      width: 18px;
                                      height: 18px;
                                      border-width: 4px;
                                      animation-duration: 1s;
                                    "
                                  ></span
                                ></span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </b-row>
                  </b-card-body>
                </b-card>
              </div>
            </b-row>
          </card>
        </div>
      </div>
    </div>
    <div class="container form-container success message welcome-container" v-if="registrationSuccess">
      <div class="card text-center">
        <h1>Welcome to XB!</h1>
        <h2>Thank You for submitting your Community Listing!</h2>
        <p>Please allow up to 24 hours for your listing to be reviewed.</p>
        <p class="text-italic">If you have any issues please contact <a href="mailto:XB Support<info@xbcommunity.com>">info@xbcommunity.com</a></p>
        <button class="btn btn-primary mt-2" @click="$router.push('/login')">Login</button>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import * as Cookies from "js-cookie";
import { Validator } from "vee-validate";
import { loadStripeCheckout } from "../../utils/loadStripe";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

Vue.use(Cookies);

export default {
  components: {
    Cropper,
  },
  data() {
    return {
      registrationSuccess: false,
      selectedSubscription: null,
      stripe: undefined,
      card: undefined,
      cardInput: undefined,
      billing: {
        card: {
          fullName: undefined,
          cardNumber: undefined,
          expiration: undefined,
          cv: undefined,
          zipCode: undefined,
        },
        address: {
          address: [null],
          city: undefined,
          state: undefined,
          zipCode: undefined,
        },
        paymentMethod: undefined,
      },
      subscriptions: null,
      business: {
        name: "",
        location: {
          address: {
            line1: "",
            line2: "",
          },
        },
        website: "",
        email: "",
        phone: "",
        description: {
          short: "",
          long: "",
        },
        images: {
          mainImage: 0,
          otherImage: [],
        },
      },
      ads: {
        adsCategory: null,
        adsCity: null,
      },
      urlImage: [],
      categories: [
        { value: null, text: "Please select role", disabled: true },
        { value: 1, text: "News" },
        { value: 2, text: "Dining" },
        { value: 3, text: "Arts & Entertainment" },
        { value: 4, text: "Community" },
        { value: 5, text: "Business" },
        { value: 6, text: "Wellness" },
      ],
      action: "create",
      user: {},
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      password: "",
      role: null,
      disableSubmitButton: true,
      profileLoaded: false,
      resetProfileData: {},
      passwordFieldType: {
        password: "password",
      },
      iconType: {
        password: "far fa-eye",
      },
      progressWidth: 0,
      progressLabel: "",
      hasNumber: false,
      hasUpperCase: false,
      hasLowerCase: false,
      hasLowercaseUppercase: false,
      hasSpecialChar: false,
      hasPeriodDash: false,
      hasError: false,
      changePasswordError: "",
      passwordShow: false,
      formData: new FormData(),
      communityCategory: null,
      imageError: "",
      hasImageError: false,
      hasCardError: false,
      cardError: "",
      cropperImages: [],
      limitations: {
        maxWidth: 1024,
        maxHeight: 576,
        minWidth: 0,
        minHeight: 0,
        aspectRatio: 4 / 3,
      },
      emailIsExist: false,
      allowedImages: null,
      selectedPlan: null,
    };
  },
  computed: {
    cities() {
      let data = [];
      this.cityList.map((city) => {
        data.push({ value: city._id, text: city.city });
      });

      return [
        { value: null, text: "Please select city", disabled: true },
        ...data,
      ];
    },
    cityList: {
      get: function () {
        return this.$store.state.cities.cities;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    communityCategories() {
      let data = [];
      this.communityCategoryList.map((communityCategory) => {
        data.push({
          value: communityCategory._id,
          text: communityCategory.title,
        });
      });

      return [
        {
          value: null,
          text: "Please select community category",
          disabled: true,
        },
        ...data,
      ];
    },
    communityCategoryList: {
      get: function () {
        return this.$store.state.connections.communityCategories;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    subCategories() {
      let data = [];
      this.subCategoryList.map((subCategory) => {
        data.push({ value: subCategory._id, text: subCategory.title });
      });

      return [
        { value: null, text: "Please select sub category", disabled: true },
        ...data,
      ];
    },
    subCategoryList: {
      get: function () {
        return this.$store.state.connections.subCategories;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    loading() {
      return this.$store.state.user.registerLoading;
    },
    adminUserList() {
      return this.$store.state.admin.userList.all || null;
    },
    billingRequired() {
      return this.selectedSubscription ? this.subscriptions.find(s => s._id == this.selectedSubscription).price != 0 : true
    }
  },
  methods: {
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    selectMainImage(index) {
      this.business.images.mainImage = index;
    },
    onFileChange(e) {
      const { files } = e.target;
      const self = this;

      this.hasImageError = false;

      if (files.length > this.allowedImages) {
        self.business.images.otherImage = [];
        self.urlImage = [];

        return self.$notify({
          message: `You can only upload upto ${this.allowedImages} image(s).`,
          timeout: 4000,
          icon: "fas fa-exclamation-triangle",
          type: "danger",
        });
      }

      self.business.images.otherImage = [];
      self.business.images.mainImage = undefined;

      for (var i = 0; i < files.length; i++) {
        self.business.images.otherImage.push({
          index: i,
          fileName: files[i].name,
          image: URL.createObjectURL(files[i]),
        });
        self.formData.append("images", files[i]);
      }
      self.business.images.mainImage = 0;
    },
    passwordCheck: function () {
      const password = this.password;
      this.hasNumber = /\d/.test(password);
      this.hasUpperCase = /[A-Z]/.test(password);
      this.hasLowerCase = /[a-z]/.test(password);
      this.hasLowercaseUppercase =
        /[a-z]/.test(password) && /[A-Z]/.test(password);
      this.hasSpecialChar = /[!@#/$%^&*)(+=#><;:,._-]/.test(password);
      this.hasPeriodDash = !/^\s*?\.|-.*$/.test(password);
      this.level = 0;

      var progressBar = document.querySelectorAll(".passwordMeter");
      var charCounts = {};
      var numCount = 0;
      var upperCaseCount = 0;
      var specialCharCount = 0;

      for (var i = 0; i < password.length; i++) {
        if (/\d/.test(password.charAt(i))) numCount++;
        if (/[A-Z]/.test(password.charAt(i))) upperCaseCount++;
        if (/[!@#/$%^&*)(+=#><;:,._-]/.test(password.charAt(i)))
          specialCharCount++;
      }

      charCounts.numCount = numCount;
      charCounts.ucCount = upperCaseCount;
      charCounts.scCount = specialCharCount;

      this.progressWidth = this.calcPassStrength(
        password.length,
        this.hasNumber,
        this.hasLowercaseUppercase,
        charCounts,
        this.hasSpecialChar,
        this.hasPeriodDash
      );

      if (this.progressWidth <= 40) {
        [].forEach.call(progressBar, function (el) {
          el.classList.remove("bg-warning");
          el.classList.remove("bg-success");
          el.classList.add("bg-danger");
        });
        this.progressLabel = this.progressWidth >= 30 ? "Bad Password" : "Bad";
        this.$validator.errors.add({
          field: "password",
          msg: "Password must meet the criteria",
          scope: "account",
        });
      } else if (this.progressWidth > 40 && this.progressWidth < 75) {
        [].forEach.call(progressBar, function (el) {
          el.classList.remove("bg-danger");
          el.classList.remove("bg-success");
          el.classList.add("bg-warning");
        });
        this.progressLabel = "Weak Password";
        this.$validator.errors.add({
          field: "password",
          msg: "Password must meet the criteria",
          scope: "account",
        });
      } else if (this.progressWidth >= 75) {
        [].forEach.call(progressBar, function (el) {
          el.classList.remove("bg-warning");
          el.classList.remove("bg-danger");
          el.classList.add("bg-success");
        });
        this.progressLabel = "Strong Password";

        this.$nextTick(() => {
          this.$validator.errors.remove("password");
        });
      }
    },
    calcPassStrength: function (
      len,
      num,
      lowCap,
      charCounts,
      specialChar,
      periodDash
    ) {
      var level = 0;
      if (len >= 8) {
        level += 18;
      }
      if (charCounts.ucCount === 2) {
        level += 10;
      }
      if (charCounts.ucCount > 2) {
        level += 4;
      }
      if (num) {
        level += 12;
      }
      if (charCounts.numCount > 2) {
        level += 5;
      }
      if (lowCap) {
        level += 22;
      }
      if (specialChar) {
        level += 23;
      }
      if (charCounts.scCount === 2) {
        level += 10;
      }
      if (charCounts.scCount >= 3) {
        level += 12;
      }
      // if (periodDash) { level += 10 }
      if (len === 0) {
        level = 0;
      }
      // console.log(len)
      // console.log(level)
      return level;
    },
    addNewBusiness() {
      const promiseBtn = document.getElementById("processNewUser");
      const promiseBtnInner = promiseBtn.querySelector(
        ".promise-btn__spinner-wrapper"
      );
      const btnLoader = promiseBtn.querySelector(".btn-loader");
      btnLoader.classList.remove("hidden");
      promiseBtnInner.classList.remove("hidden");
      promiseBtn.setAttribute("disabled", "disabled");

      const bodyWrapper = document.getElementById("processAdminForm");
      bodyWrapper.classList.add("non-clickable");

      if (this.cardInput == undefined) {
        this.hasCardError = true;
        this.cardError = "Please input card number";
      }

      if (this.business.images.otherImage.length == 0) {
        this.hasImageError = true;
        this.imageError = "Please upload images";
      }
      return this.$validator.validateAll().then(async (isValid) => {
        if (isValid) {
          if (this.billingRequired) {
            const paymentMethod = await this.stripe.createPaymentMethod({
              type: "card",
              card: this.card,
              billing_details: {
                name: this.billing.card.fullName,
                email: this.email,
                phone: this.business.phone,
              },
            });

            this.billing.paymentMethod = paymentMethod.paymentMethod;
          }

          const user = {
            firstname: this.$options.filters.capitalizeWords(this.firstname),
            lastname: this.$options.filters.capitalizeWords(this.lastname),
            email: this.email.toLowerCase(),
            password: this.password,
            business: this.business,
            ads: this.ads,
            billing: this.billing,
            phone: this.phone,
            subscription: this.selectedSubscription,
            plan: this.selectedPlan,
          };

          // lowercase the following
          user.business.website = user.business.website
            ? user.business.website.replace(/^https/i, "https")
            : '';
          user.business.website = user.business.website
            ? user.business.website.replace(/^http/i, "http")
            : '';
          user.business.website = user.business.website
            ? user.business.website.replace(/www./i, "www.")
            : '';

          user.business.newPreviewCoordinates = [];
          // Send Cropper Image to API
          for (var i = 0; i < this.business.images.otherImage.length; i++) {
            const { canvas, coordinates } = this.$refs.cropper[i].getResult();
            const cover = this.dataURLtoFile(
              canvas.toDataURL(),
              this.business.images.otherImage[i].fileName
            );
            this.formData.append("images_preview", cover);
            user.business.newPreviewCoordinates.push(coordinates);
          }

          this.formData.set("user", JSON.stringify(user));

          this.$store
            .dispatch("user/PUBLIC_REGISTER_USER", this.formData)
            .then((res) => {
              console.log("REGISTRATION_RESP:", res);

              if (res.data.success) {
                this.user = {};
                this.name = "";
                this.email = "";
                this.password = "";
                (this.role = null),
                  (this.business = {
                    name: "",
                    address: {
                      line1: "",
                      line2: "",
                    },
                    email: "",
                    phone: "",
                  });

                this.registrationSuccess = true;


                btnLoader.classList.add("hidden");
                promiseBtnInner.classList.add("hidden");

                // this.$notify({
                //   message: this.$options.filters.capitalizeWords(
                //     "Thanks for your submission, please allow up to 24 hrs for approval."
                //   ),
                //   timeout: 5000,
                //   icon: "fas fa-check",
                //   type: "success",
                // });

                // const self = this;
                // setTimeout(function () {
                //   self.$router.push({path: '/login', replace: true})
                // }, 3000);
              } else {
                console.log(res.data.error);
                if (res.data.error === "error_user_already_exist") {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      "Email already in use"
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                } else if (res.data.error === "Error_creating_business") {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      "Business name already in use"
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                } else {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      "Error creating user"
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                }
              }
              promiseBtnInner.classList.add("hidden");
              promiseBtn.removeAttribute("disabled", "disabled");
              bodyWrapper.classList.remove("non-clickable");
            })
            .catch((err) => {
              console.log("REGISTRATION_ERR:", err);
              err.then((error) => {
                console.log("THEN ERROR:", { error });
                if (error.errMessage === "Email already exist.") {
                  this.emailIsExist = true;
                }
                if (error.errMessage == "error_creating_business") {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      "Business name already in use!"
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                  this.hasError = true;
                  promiseBtnInner.classList.add("hidden");
                  promiseBtn.removeAttribute("disabled", "disabled");
                  bodyWrapper.classList.remove("non-clickable");
                } else {
                  this.$notify({
                    message: this.$options.filters.capitalizeWords(
                      error.errMessage
                    ),
                    timeout: 4000,
                    icon: "fas fa-exclamation-triangle",
                    type: "danger",
                  });
                  this.hasError = true;
                  promiseBtnInner.classList.add("hidden");
                  promiseBtn.removeAttribute("disabled", "disabled");
                  bodyWrapper.classList.remove("non-clickable");
                }
              });
            });
        } else {
          this.$notify({
            message: this.$options.filters.capitalizeWords(
              "Please complete all required fields."
            ),
            timeout: 4000,
            icon: "fas fa-exclamation-triangle",
            type: "danger",
          });
          btnLoader.classList.add("hidden");
          promiseBtnInner.classList.add("hidden");
          promiseBtn.removeAttribute("disabled", "disabled");
          bodyWrapper.classList.remove("non-clickable");
        }
      });
    },
    clearFields() {
      this.$validator.reset();
      this.errors.clear();
      this.oldPassword =
        this.password =
        this.confirmPassword =
        this.changePasswordError =
        this.progressLabel =
          "";
      this.progressWidth = 0;
      this.hasNumber =
        this.hasUpperCase =
        this.hasLowerCase =
        this.hasLowercaseUppercase =
        this.hasSpecialChar =
        this.hasPeriodDash =
        this.hasError =
          false;
      this.cancelUpdateAccount();
    },
    switchVisibility(field) {
      this.passwordFieldType[field] =
        this.passwordFieldType[field] === "password" ? "text" : "password";
      this.iconType[field] =
        this.iconType[field] === "far fa-eye"
          ? "far fa-eye-slash"
          : "far fa-eye";
    },
    selectSubscription(subscription, numberOfImages, plan_id) {
      if (subscription !== this.selectedSubscription) {
        this.urlImage = [];
        this.business.images.otherImage = [];
        this.business.images.mainImage = undefined;

        this.allowedImages = numberOfImages;
        this.selectedSubscription = subscription;
        this.selectedPlan = plan_id;
      }
    },
    getCommunityCategories() {
      this.$store.dispatch("connections/GET_COMMUNITY_CATEGORY_LIST");
    },
    getSubCategories() {
      this.business.category = null;
      this.$store.dispatch(
        "connections/GET_SUB_CATEGORY_LIST",
        this.communityCategory
      );
    },
    getCities() {
      this.$store.dispatch("cities/GET_CITIES", { city: "All" });
    },
    getSubscriptions() {
      this.$store
        .dispatch("subscriptions/GET_SUBSCRIPTIONS", { isActive: 1, hidden: 0 })
        .then((resp) => {
          this.subscriptions = resp;
          console.log(resp);
          // Get the first subscription
          this.selectedSubscription = this.subscriptions[0]._id;
          this.allowedImages = this.subscriptions[0].numberOfImages;
          this.selectedPlan = resp[0].plan_id;
        });
    },
  },
  watch: {
    password(value) {
      if (value.length > 0) {
        this.passwordShow = true;
      } else {
        this.passwordShow = false;
      }
    },
  },
  mounted() {
    this.getSubscriptions();
    this.getCities();
    this.getCommunityCategories();
    const stripePk =
      process.env.NODE_ENV == "development" || process.env.NODE_ENV == "staging"
        ? `${process.env.VUE_APP_STRIPE_PK_TEST_KEY}`
        : `${process.env.VUE_APP_STRIPE_PK_LIVE_KEY}`;
    const style = {
      base: {
        color: "#8898aa",
        fontFamily: '"Avenir Book", sans-serif',
        fontSize: "15px",
      },
    };

    // Load Stripe.js
    const initializeStripe = () => {
      this.stripe = Stripe(stripePk);
      this.card = this.stripe.elements().create("card", { style });

      this.card.mount("#card-element");
      this.card.on("change", (card) => {
        this.hasCardError = false;
        this.cardInput = card;
      });
    };

    !window.Stripe
      ? loadStripeCheckout(stripePk, "v3", initializeStripe)
      : initializeStripe();

    if (process.env.NODE_ENV == 'development') {
      this.business.name = 'HiloLabs'
      this.business.location.address.line1 = '123 Test St'
      this.business.description.short = 'asdfasdfasdf'
      this.business.description.long = 'sakdfnbksajdfkjlasdnfkjasdbfkjasdf'
      this.firstname = 'Connor'
      this.lastname = 'Sparling'
      this.phone = '123-123-1234'
      this.email = `connor+xb.business.dev${`000000000${(Math.random()*100000).toFixed(0)}`.slice(-6)}@hilolabs.com`
      this.password = '123@asdASD'
      this.passwordCheck()
    }
  },
};
</script>

<style lang="scss" scoped>
.custom-header-bg {
  background: var(--primary) !important;
  opacity: 1;
  border-bottom: 10px solid var(--primary);
}
.custom-header {
  img {
    width: 90%;
    max-width: 500px;
    margin: auto;
  }
}

.form-body {
  padding: 20px;
  label {
    color: black;
  }
  input {
    background: transparent;
    border: none;
    border-bottom: 2px solid black;
    color: black !important;
    &::placeholder {
      color: grey !important;
    }
    &:focus {
      background: transparent;
      border-color: var(--primary);
    }
  }
  select {
    background: transparent;
    border: none;
    border-bottom: 2px solid black;
    color: grey !important;
    &::placeholder {
      color: grey !important;
    }
    &:focus {
      background: transparent;
      border-color: var(--primary);
    }
  }

  textarea {
    background: transparent;
    border: none;
    border-bottom: 2px solid black;
    color: black !important;
    &::placeholder {
      color: grey !important;
    }
    &:focus {
      background: transparent;
      border-color: var(--primary);
    }
  }
}

#business_information {
  background: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  .form-head {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background: var(--primary);
    border: 2px solid white;
    padding: 5px 15px 3px;

    h1 {
      color: white;
    }
  }

  .form-body {
    .custom-file-label {
      border: none;
      border-bottom: 2px solid black;
    }
    .preview-images {
      h2 {
        padding: 20px 0;
        font-size: 0.875rem;
        color: black !important;
      }
      .img-container {
        position: relative;
        width: 200px;
        // height: 200px;

        img {
          cursor: pointer;
        }

        .selected {
          border: 3px solid var(--primary);
          &:after {
            background-image: url("../../assets/images/checked.png");
            background-size: 20px 20px;
            background-repeat: no-repeat;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 10px;
            right: 10px;
            content: "";
          }

          img {
            display: block;
            margin: auto;
          }
        }
      }
    }
  }
}
#user_account {
  background: white;

  .form-head {
    background: var(--primary);
    padding: 5px 15px;
    h1 {
      color: white;
    }
  }
  .form-body {
    input {
      &:focus {
        border-color: var(--primary);
      }
    }
  }
}
#subscription {
  background: white;

  .form-head {
    background: var(--primary);
    padding: 5px 15px;
    h1 {
      color: white;
    }
  }
  .form-body {
    .subscription-container {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .subscription-card {
        padding: 30px 30px 10px;
        height: auto;
        width: 400px;
        border: 1px solid white;
        background: var(--primary) !important;
        color: white;

        h3 {
          width: 85%;
          // min-height: 75px;
          color: white;
          font-size: 25px;
          font-weight: 900;
        }

        p {
          margin-top: 20px;
          margin-bottom: -10px;
        }

        .as-low {
          margin-top: 10px;
        }

        .price {
          font-size: 50px;
          font-weight: 900;

          span {
            font-size: 20px;
            font-weight: 200;
          }
        }

        .trial {
          width: 100%;
          font-weight: 300;
          margin-top: 10px;

          b, strong {
            font-weight: 600;
            // color: var(--white);
          }
        }

        .select-btn {
          display: block;
          margin: 20px auto;
          width: 100%;
          height: 40px;
          border-radius: 5px;
          color: var(--primary-contrast);
          background: var(--primary);
          border: 1px solid var(--primary-contrast);
          font-weight: 900;
        }

        .description {
          list-style: armenian !important;
        }

        &.selectedSubscription {
          position: relative;

          h3 {
            //color: var(--primary);
          }
          .select-btn {
            background: var(--secondary);
            color: var(--secondary-contrast);
            &:focus {
              outline-color: black;
              border: none;
            }
          }
          // border: 5px solid var(--primary);
        }

        .note-selected {
          img {
            position: absolute;
            top: 20px;
            right: 20px;
            display: block;
            width: 40px;
          }
        }
      }
    }
  }
}
#advertisement_information {
  background: white;
  .form-head {
    background: var(--primary);
    padding: 5px 15px;

    h1 {
      color: white;
    }
  }

  .form-body {
    .custom-file-label {
      border: none;
      border-bottom: 2px solid black;
    }
    .preview-images {
      h2 {
        padding: 20px 0;
        font-size: 0.875rem;
        color: black !important;
      }
      .img-container {
        position: relative;
        width: 200px;
        // height: 200px;

        img {
          cursor: pointer;
        }

        .selected {
          border: 3px solid var(--primary);
          &:after {
            background-image: url("../../assets/images/checked.png");
            background-size: 20px 20px;
            background-repeat: no-repeat;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 10px;
            right: 10px;
            content: "";
          }

          img {
            display: block;
            margin: auto;
          }
        }
      }
    }
  }
}
#card_information {
  background: white;

  .form-head {
    background: var(--primary);
    padding: 5px 15px;
    h1 {
      color: white;
    }
  }
}

// Override
.card-body {
  padding: 0 !important;
}
.row {
  padding: 0;
  margin: 0;
}
.remove-padding {
  padding: 0;
}
.minHeight {
  margin-top: -57px !important;
}
.card {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

input[type="text"].form-control,
input[type="email"].form-control,
input[type="password"].form-control {
  color: black !important;
}
.password-validator-text {
  color: black !important;
}
#card-element {
  background: transparent !important;
  border: none;
  border-bottom: 2px solid black;
  color: green !important;
}
h2 {
  color: white;
}

h2.card-note {
  color: #525f7f;
}
#processNewUser {
  background: var(--primary);
  display: block;
  margin: auto;
  border: var(--primary);
}
.error-msg {
  color: red;
}
.image-error-msg {
  padding: 10px;
  font-size: 80%;
  font-weight: 900;
  color: #f4516c;
}
.card-error-msg {
  padding: 10px;
  font-size: 80%;
  color: #f4516c;
}

.state-err-icon {
  bottom: 58px !important;
  right: 38px;
}

.show-pass-icon {
  position: absolute;
  bottom: 15px;
  right: 30px;
}
.non-clickable {
  pointer-sponsors: none;
}
hr.divider {
  border-top: 2px dashed var(--primary);
}
h3.divider-title {
  text-transform: uppercase;
  text-align: center;
  color: black;
  margin-bottom: 25px;
}

.welcome-container {
  width: 100%;

  .card {
    margin: auto;
    margin-top: 60px;
    max-width: 650px;
    padding: 30px 50px 40px;
    border-top-left-radius: unset;
    border-top-right-radius: unset;
    border-radius: 1rem;
    background-color: white;

    h1 {
      font-size: 3rem;
      color: var(--primary)
    }

    h2 {
      font-size: 1.5rem;
      color: var(--primary)
    }

    p {
      font-size: 1rem;
      color: var(--primary)
    }
  }
}

@media only screen and (max-width: 600px) {
  .subscription-card {
    padding: 25px 30px !important;
    width: 100% !important;
    h3 {
      width: 80%;
    }
    p {
      margin-bottom: 0px !important;
    }
    .price {
      font-size: 28px !important;
    }
    .note-selected {
      img {
        top: 28px !important;
        right: 28px !important;
      }
    }
  }
  .custom-header {
    img {
      width: 100%;
    }
  }
  #subscription {
    .form-body {
      overflow: auto;
      .subscription-container {
        flex-direction: column;

        .subscription-card {
          margin-bottom: 20px;
          height: auto;
        }
      }
    }
  }
  .form-container {
    padding: 0 !important;
  }
  .welcome-container {
    background-color: white;
    max-width: unset; 
  
    .card {
      box-shadow: none;
      margin-top: 0;
      height: 100%;
      padding-bottom: 60px;
      border-radius: 0;
      // max-width: unset;  
    }
  }
}

figure {
  margin: 0 !important;
  img {
    width: 100% !important;
  }
}

.cropper {
  &.selected {
    border: 5px solid var(--primary);
    &:after {
      background-image: url("../../assets/images/checked.png");
      background-size: 40px 40px;
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
      position: absolute;
      top: 10px;
      right: 10px;
      content: "";
    }

    img {
      display: block;
      margin: auto;
    }
  }
}
.btn-theme {
  background: var(--primary);
  margin: 15px auto;
  display: block;
  color: white;
  width: 150px;
}
.btn-loader {
  font-size: 20px;
  &.hidden {
    display: none;
  }
}
</style>
